import React from "react";
import EventCard from "./EventCard";
import DefaultBL from "../../gallery/default/defaultBusinessLogo.png";
import CustomAdd from "../common/CustomAdd";

function HomeFeaturedEventsSmallScreen({ events, exchangeRate }) {
  return (
    <div className="row">
      <CustomAdd exchangeRate={exchangeRate} />
      <div className="col-12 card-holder" style={{ height: "630px" }}>
        {events && (
          <div
            id="carouselExampleSlidesOnly"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              {events.map((element, index) => {
                var customActive = "";
                if (index === 0) {
                  customActive = "active";
                }
                return (
                  <div
                    className={`carousel-item ${customActive}`}
                    key={`carousel_${index}`}
                  >
                    <EventCard
                      //imageHeight="502px"
                      image={
                        element.image_name ? element.image_path : DefaultBL
                      }
                      title={element.name ? element.name : ""}
                      address1={element.address1 ? element.address1 : ""}
                      location={element.location ? element.location : ""}
                      postalcode={
                        element.postal_code ? element.postal_code : ""
                      }
                      page={element.page ? element.page : ""}
                      type={
                        element.category
                          ? element.category.charAt(0).toUpperCase() +
                            element.category.slice(1)
                          : ""
                      }
                      startTicketDate={element.ticket_start_at}
                      startDate={element.start_date}
                      endDate={element.end_date}
                      showStartDate={element.show_start_date}
                      adultOnly={element.adult_event}
                      detail_url={element.page + "/" + element.slug}
                      views={element.views}
                      price={
                        element.tickets.length > 0
                          ? element.tickets[0].price
                          : null
                      }
                      home="home"
                    />
                  </div>
                );
              })}
              {events && (
                <>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleSlidesOnly"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleSlidesOnly"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HomeFeaturedEventsSmallScreen;
