import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetGuest } from "../../app/features/user/userSlice";
import { BeatLoader } from "react-spinners";
import { setCart, setBooking } from "../../app/features/event/eventSlice";
import { useHistory } from "react-router-dom";

function EventGuestCheckoutForm(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.event.cart);
  const currentEvent = useSelector((state) => state.event.currentEvent);
  const items = useSelector((state) => state.event.items);
  const [formError, setFormError] = useState(null);
  const [couponError, setCouponError] = useState(null);
  let grandTotal = cart.subTotal;
  const [couponData, setCouponData] = useState({
    coupon_code: "",
    user_id: currentEvent.user_id,
    event_id: currentEvent.id,
    amount: cart.grandTotal,
  });
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    confirm_email: "",
    phone: "",
    address1: "",
    address2: "",
    location_id: "",
    postal_code: "",
  });

  const goToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const gotoLogin = () => dispatch(resetGuest());
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const registerUser = async (event) => {
    event.preventDefault();
    const loginButton = event.target;
    const loader = document.getElementById("registerloader");
    goToTop();
    if (formData.first_name === "") {
      setFormError("Enter first name!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (formData.last_name === "") {
      setFormError("Enter last name!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (!isValidEmail(formData.email)) {
      setFormError("Enter valid email!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (!isValidEmail(formData.confirm_email)) {
      setFormError("Enter valid email for confirmation!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (formData.email !== formData.confirm_email) {
      setFormError("Email not matched!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (formData.phone === "") {
      setFormError("Enter your phone number!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (formData.address1 === "") {
      setFormError("Enter address 1!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (formData.location_id === "") {
      setFormError("Enter your city!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (formData.postal_code === "") {
      setFormError("Enter postal code!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else {
      loginButton.classList.remove("btn-register");
      loginButton.classList.add("btn-disable-register");
      loader.style.display = "block";
      var filteredItems = items.filter(function (el) {
        return el != null;
      });

      await axios
        .request({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/api/sanctum/csrf-cookie`,
        })
        .then(async (sanRep) => {
          await axios
            .request({
              method: "POST",
              url: `${process.env.REACT_APP_BASE_URL}/api/register`,
              data: formData,
            })
            .then(async (registerResponse) => {
              var bookingData = {
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.email,
                phone: formData.phone,
                address1: formData.address1,
                address2: formData.address2,
                location_id: formData.location_id,
                postal_code: formData.postal_code,
                buyer_id: registerResponse.data.data.id,
                total: cart.grandTotal,
                event_id: currentEvent.id,
                user_id: currentEvent.user_id,
                coupon_id: cart.coupon_id ? cart.coupon_id : null,
                discount_amount: cart.discount ? cart.discount : null,
                status: cart.grandTotal === 0 ? 1 : 0,
                items: filteredItems,
              };
              let merchantId = "contact@sahisearch.com";
              await axios
                .request({
                  method: "GET",
                  url: `${process.env.REACT_APP_BASE_URL}/api/users/${currentEvent.user_id}`,
                  headers: {
                    Authorization: process.env.REACT_APP_API_KEY,
                  },
                })
                .then(async function (userResponse) {
                  if (userResponse.data.data.marchant_id) {
                    merchantId = userResponse.data.data.marchant_id;
                  }
                  await axios
                    .request({
                      method: "POST",
                      url: `${process.env.REACT_APP_BASE_URL}/api/bookings`,
                      data: bookingData,
                      headers: {
                        Authorization: process.env.REACT_APP_API_KEY,
                      },
                    })
                    .then(async function (response) {
                      dispatch(setBooking(response.data.data));
                      const bookingSlug = response.data.data.slug;
                      if (cart.grandTotal === 0) {
                        history.push(`/ticket/${bookingSlug}`);
                        window.location.reload();
                        return;
                      }else{
                      const nochexData = {
                        merchant_id: merchantId,
                        amount: bookingData.total,
                        //test_transaction: "100",
                        order_id: response.data.data.id,
                        email_address: bookingData.email,
                        customer_phone_number: bookingData.phone,
                        billing_fullname: bookingData.first_name + " " + bookingData.last_name,
                        billing_address: bookingData.address1,
                        billing_postcode: bookingData.postal_code,
                        billing_city: bookingData.location_id,
                        callback_url: "https://sahisearch.com/manage/public/nochex_callback",
                        success_url: "https://sahisearch.com/ticket/" + bookingSlug,
                      };
                      const form = document.createElement("form");
                      form.method = "POST";
                      form.action = "https://secure.nochex.com/";

                      for (const key in nochexData) {
                        if (nochexData.hasOwnProperty(key)) {
                          const hiddenField = document.createElement("input");
                          hiddenField.type = "hidden";
                          hiddenField.name = key;
                          hiddenField.value = nochexData[key];

                          form.appendChild(hiddenField);
                        }
                      }

                      document.body.appendChild(form);
                      form.submit();

                      return;
                    }
                    })
                    .catch(function (error) {
                      console.error(error);
                    });
                })
                .catch(function (error) {
                  console.error(error);
                });
            })
            .catch(function (error) {
              setFormError(error.response.data.message);
              setTimeout(() => {
                setFormError(null);
              }, 5000);
              loginButton.classList.remove("btn-disable-register");
              loginButton.classList.add("btn-register");
              loader.style.display = "none";
              console.error(error);
            });
        });
    }
  };
  const showCoupon = (event) => {
    event.preventDefault();
    const couponContainer = document.getElementById("coupon_container");
    couponContainer.style.display = "block";
    const couponLink = document.getElementById("coupon_link");
    couponLink.style.display = "none";
  };
  const setCooupon = (event) => {
    setCouponData({
      ...couponData,
      [event.target.name]: event.target.value,
    });
  };
  const handleCoupon = async (event) => {
    event.preventDefault();
    const loader = document.getElementById("couponloader");
    if (couponData.coupon_code === "") {
      setCouponError("Please enter coupon code!");
      setTimeout(() => {
        setCouponError(null);
      }, 5000);
      return;
    } else {
      loader.style.display = "block";

      await axios
        .request({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/api/sanctum/csrf-cookie`,
        })
        .then(async (sanRep) => {
          await axios
            .request({
              method: "POST",
              url: `${process.env.REACT_APP_BASE_URL}/api/getcoupon`,
              data: couponData,
            })
            .then(function (response) {
              if (response.data.data.discount_type === 1) {
                grandTotal = grandTotal - response.data.data.discount_value;
              } else if (response.data.data.discount_type === 2) {
                const valuePercent = (grandTotal / 100) * response.data.data.discount_value;
                grandTotal = grandTotal - valuePercent;
              }
              if (grandTotal >= 0) {
                const updateCart = {
                  ...cart,
                  grandTotal: grandTotal,
                  discount: response.data.data.discount_value,
                  discount_type: response.data.data.discount_type,
                  coupon_id: response.data.data.id,
                };
                dispatch(setCart(updateCart));
                loader.style.display = "none";
              } else {
                setCouponError("This coupon is not applicable");
                setTimeout(() => {
                  setCouponError(null);
                }, 5000);
                loader.style.display = "none";
              }

              return;
            })
            .catch(function (error) {
              setCouponError(error.response.data.message);
              setTimeout(() => {
                setCouponError(null);
              }, 5000);
              loader.style.display = "none";
              console.error(error);
            });
          loader.style.display = "none";
        });
    }
  };
  return (
    <div className="login-container">
      <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <h3 className="register-heading">Enter your contact details</h3>
          <h3 className="remaining-time">Time remaining : {props.timeRemaining}</h3>
          <p className="not-reserved">Tickets are not reserved until you have completed payment.</p>
          <form className=" g-3" autoComplete="off">
            {formError && (
              <div className="alert alert-danger" role="alert">
                {formError}
              </div>
            )}
            <div className="row">
              <h3 className="register-sub-heading">Personal details</h3>
              <div className="col-6">
                <label htmlFor="first_name" className="form-label input-label">
                  First name<span className="field-required">*</span>
                </label>
                <input type="text" className="form-control input-text-size" name="first_name" onChange={handleChange} value={formData.first_name} required={"required"} />
              </div>
              <div className="col-6">
                <label htmlFor="last_name" className="form-label input-label">
                  Last name<span className="field-required">*</span>
                </label>
                <input type="text" className="form-control input-text-size" name="last_name" onChange={handleChange} value={formData.last_name} required={"required"} />
              </div>
              <div className="col-12">
                <label htmlFor="email" className="form-label input-label">
                  Email<span className="field-required">*</span>
                </label>
                <input type="email" className="form-control input-text-size" name="email" onChange={handleChange} value={formData.email} required={"required"} />
              </div>
              <div className="col-12">
                <label htmlFor="confirm_email" className="form-label input-label">
                  Confirm Email<span className="field-required">*</span>
                </label>
                <input type="email" className="form-control input-text-size" name="confirm_email" onChange={handleChange} value={formData.confirm_email} required={"required"} />
              </div>
              <div className="col-12">
                <label htmlFor="phone" className="form-label input-label">
                  Phone number<span className="field-required">*</span>
                </label>
                <input type="text" className="form-control input-text-size" name="phone" onChange={handleChange} value={formData.phone} required={"required"} />
              </div>
            </div>
            <div className="row">
              <h3 className="mt-3 register-sub-heading">Billing address details</h3>
              <div className="col-12">
                <label htmlFor="address1" className="form-label input-label">
                  Address Line 1<span className="field-required">*</span>
                </label>
                <input type="text" className="form-control input-text-size" name="address1" onChange={handleChange} value={formData.address1} required={"required"} />
              </div>
              <div className="col-12">
                <label htmlFor="address2" className="form-label input-label">
                  Address Line 2
                </label>
                <input type="text" className="form-control input-text-size" name="address2" onChange={handleChange} value={formData.address2} />
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <label htmlFor="location_id" className="form-label input-label">
                  City<span className="field-required">*</span>
                </label>
                <input type="text" className="form-control input-text-size" name="location_id" onChange={handleChange} value={formData.location_id} />
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <label htmlFor="postal_code" className="form-label input-label">
                  Post code<span className="field-required">*</span>
                </label>
                <input type="text" className="form-control input-text-size" name="postal_code" onChange={handleChange} value={formData.postal_code} required={"required"} />
              </div>
            </div>
            <div className="col-12 text-center mt-4" id="coupon_container" style={{ display: "none" }}>
              {couponError && (
                <div className="alert alert-danger" role="alert">
                  {couponError}
                </div>
              )}
              <p className="enter-coupon">Enter your coupon code below</p>
              {/* <label htmlFor="coupon_code" className="form-label input-label">
                Coupon code
              </label> */}
              <div className="input-group mb-3">
                <input name="coupon_code" id="coupon_code" type="text" className="form-control" placeholder="Enter coupon code" aria-describedby="button-addon1" defaultValue={couponData.coupon_code} onChange={(event) => setCooupon(event)} />
                <button className="btn btn-outline-secondary btn-small-coupon" type="button" id="button-addon1" onClick={handleCoupon}>
                  Use
                </button>
              </div>
              <div id="couponloader" style={{ display: "none" }}>
                <BeatLoader color="#C6153B" />
              </div>
            </div>
            <p className="coupon-link mt-4" id="coupon_link">
              <a href="/" className="sign-up" onClick={(event) => showCoupon(event)}>
                Use Coupon Code
              </a>
            </p>
            <div className="row mt-4">
              <div className="col-12 text-center">
                <button type="button" className="btn btn-register mb-3" onClick={registerUser}>
                  Checkout
                </button>
                <div id="registerloader" style={{ display: "none" }}>
                  <BeatLoader color="#C6153B" />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="event-align-middle">
            <h3 className="login-event-title">{currentEvent.name}</h3>
            <p className="ticket-waiting">
              {cart.productsCount}&nbsp;
              {cart.productsCount === 1 ? "ticket" : "tickets"}
            </p>
            <p className="inclusive-fee">All ticket prices are inclusive of booking fees</p>
            <p className="not-reserved">Tickets are not reserved until you have completed payment.</p>
            <h3 className="grand-total">
              £{cart.grandTotal.toFixed(2)}
              {cart.discount > 0 && (
                <span className="discount-applied-success">
                  &nbsp;(-
                  {cart.discount_type === 1 ? `£${cart.discount}` : `${cart.discount}%`}
                  &nbsp;Coupon)
                </span>
              )}
            </h3>
            <button className="btn btn-back" onClick={gotoLogin}>
              Go back to login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventGuestCheckoutForm;
