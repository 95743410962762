import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentEvent: [],
  ticketsSold: [],
  items: [],
  cart: {
    productsCount: 0,
    subTotal: 0.00,
    grandTotal: 0.00,
    discount: 0.00,
    discount_type: 0,
    coupon_id: null,
  },
  booking: [],
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    incrementTicket: (state, actions) => {
      state.ticketsSold[actions.payload].count += 1;
    },
    decrementTicket: (state, actions) => {
      state.ticketsSold[actions.payload].count -= 1;
    },
    setTickets: (state, actions) => {
      state.ticketsSold[actions.payload.id] = actions.payload;
    },
    updateEvent: (state, actions) => {
      state.currentEvent = actions.payload;
    },
    setItem: (state, actions) => {
      state.items[actions.payload.ticket_id] = actions.payload;
    },
    setCart: (state, actions) => {
      state.cart = actions.payload;
    },
    setBooking: (state, actions) => {
      state.booking = actions.payload;
    },
    resetEvent: (state) => {
      state.currentEvent = [];
      state.ticketsSold = [];
      state.booking = [];
      state.items = [];
      state.cart = {
        productsCount: 0,
        subTotal: 0.00,
        grandTotal: 0.00,
        discount: 0.00,
        discount_type: 0,
        coupon_id: null,
      };
    },
  },
});

export const {
  incrementTicket,
  decrementTicket,
  updateEvent,
  setTickets,
  setItem,
  setCart,
  setBooking,
  resetEvent,
} = eventSlice.actions;

export default eventSlice.reducer;
