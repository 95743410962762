import React from "react";
import Card from "../common/Card";
import ShowMore from "../common/ShowMore";
import { useState, useEffect } from "react";
import axios from "axios";
import DefaultBL from "../../gallery/default/defaultBusinessLogo.png";

function FeaturedBusiness() {
  const [data, setData] = useState();

  useEffect(() => {
    async function fetchData() {
      const options = {
        method: "GET",
        url: process.env.REACT_APP_BASE_URL + "/api/business",
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
        params: {
          list: "featured",
        },
      };

      await axios
        .request(options)
        .then(function (response) {
          setData(response.data.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    }
    fetchData();
  }, []);

  /* console.log(data) */
  return (
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
        <h3 className="featured-business-title">Featured Businesses</h3>
      </div>
      {data
        ? data.map((element, index) => {
            console.log(element);
            return (
              <div
                className="col-lg-6 col-md-6 col-sm-12 col-xs-12 card-holder"
                key={index}
              >
                <Card
                  imgaeWidth="305px"
                  imageHeight="75px"
                  align={"align-items-start"}
                  justifycontent={"left"}
                  image={
                    element.image_thumbnail_path
                      ? element.image_thumbnail_path
                      : DefaultBL
                  }
                  title={element.name ? element.name : ""}
                  location={element.location ? element.location : ""}
                  postalcode={element.postal_code ? element.postal_code : ""}
                  address={element.postal_code ? element.postal_code : ""}
                  page={element.page ? element.page : ""}
                  type={
                    element.category
                      ? element.category.charAt(0).toUpperCase() +
                        element.category.slice(1)
                      : ""
                  }
                  category_id={element.category_id}
                  detail_url={element.page + "/" + element.slug}
                  views={element.views}
                  RemoveBodyPaddingBottom={true}
                />
              </div>
            );
          })
        : false}
      <ShowMore url={"/search?category=1&location=1&page=1&limit=10"} />
    </div>
  );
}
export default FeaturedBusiness;
