import axios from 'axios';


function showLoader(){
    var globalLoader = document.getElementById('globalLoader');
    if(globalLoader){
    globalLoader.style.display="block";
    }

}
function hideLoader(){
    var globalLoader = document.getElementById('globalLoader');
    if(globalLoader){
    globalLoader.style.display="none";
    }
}
// Create a variable to keep track of the number of ongoing requests
let requestCount = 0;

// Function to increment the request count
const incrementRequestCount = () => {
    if (requestCount === 0) {
        showLoader();
        // Display the loader or trigger any action you want when the first request starts
        // For example: dispatch(showLoader());
    }
    requestCount++;
};

// Function to decrement the request count
const decrementRequestCount = () => {
    requestCount--;
    if (requestCount === 0) {
        hideLoader();
        // Hide the loader or trigger any action you want when all requests are completed
        // For example: dispatch(hideLoader());
    }
};

// Add a request interceptor to increment the request count
axios.interceptors.request.use(
    (config) => {
        incrementRequestCount();
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor to decrement the request count
axios.interceptors.response.use(
    (response) => {
        decrementRequestCount();
        return response;
    },
    (error) => {
        decrementRequestCount();
        return Promise.reject(error);
    }
);
