import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import EventsBanner from "./EventsBanner";
import EventWithUs from "./EventWithUs";
import FeaturedEvents from "./FeaturedEvents";
import UpcomingEvents from "./UpcomingEvents";
//import NativeAd from "../common/NativeAd";
import axios from "axios";
import { BeatLoader } from "react-spinners";

function EventsIndex(props) {
  const [search, setSearch] = useState({ locations: null });
  const [events, setEvents] = useState();

  async function fetchLocations() {
    await axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_BASE_URL + "/api/locations",
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
        params: {
          page: 1,
          limit: 300,
        },
      })
      .then(function (response) {
        setSearch((prevSearch) => ({
          ...prevSearch,
          locations: response.data.data,
        }));
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  async function fetchEvents() {
    const options = {
      method: "GET",
      url: process.env.REACT_APP_BASE_URL + "/api/events",
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
      },
      params: {
        list: "featured",
      },
    };

    await axios
      .request(options)
      .then(function (response) {
        setEvents(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  function toggleMobilenav() {
    var body = document.body;
    var hasClass = body.classList.contains("nav2-is-toggled");
    if (hasClass) {
      document.body.classList.remove("nav2-is-toggled");
      document.body.classList.remove("body-no-scroll");
      document.getElementById("ham").textContent = "menu";
    }
  }
  useEffect(() => {
    toggleMobilenav();
    fetchLocations();
    fetchEvents();
  }, []);
  return (
    <>
      <Helmet>
        <title>{`Sahi Search - ${props.pageTitle}`}</title>
        <meta name="description" content="" />
      </Helmet>
      {!events && !search.locations && (
        <div className="loader-in-component-body">
          <BeatLoader color="#C6153B" />
        </div>
      )}
      {events && search.locations && (
        <>
          <EventsBanner locations={search.locations} />
          <div className="container">
            <FeaturedEvents events={events} />
          </div>
          {/* <NativeAd /> */}
          <UpcomingEvents locations={search.locations} />
          {/* <NativeAd /> */}
          <EventWithUs />
        </>
      )}
    </>
  );
}

export default EventsIndex;
