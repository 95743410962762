import React from "react";
import {Link, useHistory} from "react-router-dom";

function Category(props){
    const history = useHistory();
    function handleNone(){
        history.push(history.location.pathname);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    return(
        <>
        <Link
            id={props.categoryId?props.categoryId:''}
            to={props.to?props.to:'/'}
            onClick={props.onclick?props.onclick:handleNone}
            className={props.active === 1?`${props.hiddenClass} text-decoration-none category-link col-lg-2 col-md-4 col-sm-1 active-link`:`${props.hiddenClass} text-decoration-none category-link col-lg-2 col-md-4 col-sm-12`}>
            {props.category?props.category:''}{props.count >=0 && <span className="category-count">&nbsp;({parseInt(props.count) >=0?props.count:''})</span>}
        </Link>
        </>
    );
}export default Category