import React, { useEffect, useState } from "react";
import { DashLg, Hourglass, PlusLg } from "react-bootstrap-icons";
import { setTickets, incrementTicket, decrementTicket, setItem, setCart } from "../../app/features/event/eventSlice";
import { useDispatch, useSelector } from "react-redux";

function EventTicket(props) {
  const cart = useSelector((state) => state.event.cart);
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const ticket = props.ticket;

  const dateFormat = (d) => {
    let date = new Date(d);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedDate = `${day} ${month}  ${year}, ${hours}:${minutes < 10 ? "0" + minutes : minutes} ${ampm}`;

    //const formattedDate = `${day} ${month}, ${year}`;

    return formattedDate;
  };
  const updateTicket = {
    ...ticket,
    count: count,
  };
  const item = {
    event_id: ticket.event_id,
    ticket_id: ticket.id,
    quantity: 0,
    unit_price: ticket.price,
    discount: 0.00,
    subtotal: 0.00,
    user_id: 1,
  };

  useEffect(() => {
    dispatch(setTickets(updateTicket));
  }, [ticket.event_id]);

  const add = (ticket_id) => {
    if (ticket.quantity - ticket.sold >= count + 1) {
      setCount(count + 1);
      dispatch(incrementTicket(ticket.id));
      const updateItem = {
        ...item,
        quantity: count + 1,
        subtotal: (count + 1) * ticket.price,
      };
      dispatch(setItem(updateItem));
      const updateCart = {
        ...cart,

        productsCount: parseFloat(cart.productsCount) + 1,
        subTotal: parseFloat(cart.subTotal) + parseFloat(ticket.price),
        grandTotal: parseFloat(cart.subTotal) + parseFloat(ticket.price),
      };
      dispatch(setCart(updateCart));
    }
  };
  const remove = (ticket_id) => {
    if (count > 0) {
      setCount(count - 1);
      dispatch(decrementTicket(ticket.id));
      const updateItem = {
        ...item,
        quantity: count - 1,
        subtotal: (count - 1) * ticket.price,
      };
      dispatch(setItem(updateItem));
      const updateCart = {
        ...cart,
        productsCount: parseFloat(cart.productsCount) - 1,
        subTotal: parseFloat(cart.subTotal) - parseFloat(ticket.price),
        grandTotal: parseFloat(cart.subTotal) - parseFloat(ticket.price),
      };
      dispatch(setCart(updateCart));
    }
  };

  return (
    <div className={props.disable ? props.disable : "card ticket-card"}>
      <div className="card-body">
        <div className="row">
          <div className="col-6">
            <h5 className="event-ticket-title">{ticket.name.charAt(0).toUpperCase() + ticket.name.slice(1)}</h5>
            {ticket.detail && <p>{ticket.detail}</p>}
          </div>
          <div className="col-6">
            <div className="btn-toolbar justify-content-end" role="toolbar" aria-label="Toolbar with button groups">
              <div className="btn-group" role="group">
                <button type="button" className="btn btn-ticket-cart" onClick={() => remove(ticket.id)}>
                  <DashLg />
                </button>
                <button type="button" className="btn btn-ticket-cart" style={{ disabled: "disabled" }}>
                  {count}
                </button>
                <button type="button" className="btn btn-ticket-cart" onClick={() => add(ticket.id)}>
                  <PlusLg />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 event-ticket-left">
            {parseInt(ticket.quantity) - parseInt(ticket.sold) <= 20 && parseInt(ticket.quantity) - parseInt(ticket.sold) > 10 && <>Less Than {parseInt(ticket.quantity) - parseInt(ticket.sold)} left</>}
            {parseInt(ticket.quantity) - parseInt(ticket.sold) <= 10 && parseInt(ticket.quantity) - parseInt(ticket.sold) > 0 && <>Only {parseInt(ticket.quantity) - parseInt(ticket.sold)} left</>}
            {parseInt(ticket.quantity) - parseInt(ticket.sold) === parseInt(0) && <>Sold Out</>}
          </div>
          <div className="col-12 event-ticket-price">£{ticket.price}</div>
          <div className="col-12 event-ticket-text-fade">
            <Hourglass /> Get before {dateFormat(ticket.expiry_date)}
            {/* {ticket.expiry_date} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventTicket;
