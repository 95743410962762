import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser, resetAskSignup } from "../../app/features/user/userSlice";
import { BeatLoader } from "react-spinners";

function EventRegisterCheckoutForm(props) {
  const dispacth = useDispatch();
  const [formError, setFormError] = useState(null);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    confirm_email: "",
    password: "",
    password_confirmation: "",
    phone: "",
    address1: "",
    address2: "",
    location_id: "",
    postal_code: "",
  });
  const goToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const gotoLogin = () => dispacth(resetAskSignup());
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handlePassword = (event) => {
    const password = event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    if (password.length < 6) {
      setFormError("Password must be atleast 6 character!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);
    }
  };
  const handlePasswordConfirmation = (event) => {
    const confirmPassword = event.target.value;
    const inputPassword = document.getElementsByName("password");
    const password = inputPassword[0];
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    if (confirmPassword.length < 6) {
      setFormError("Password must be atleast 6 character!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);
    } else if (password.value !== confirmPassword) {
      setFormError("Password not matched!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);
    }
  };
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const registerUser = async (event) => {
    event.preventDefault();
    const loginButton = event.target;
    const loader = document.getElementById("registerloader");
    goToTop();
    if (formData.first_name === "") {
      setFormError("Enter first name!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (formData.last_name === "") {
      setFormError("Enter last name!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (!isValidEmail(formData.email)) {
      setFormError("Enter valid email!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (!isValidEmail(formData.confirm_email)) {
      setFormError("Enter valid email for confirmation!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (formData.email !== formData.confirm_email) {
      setFormError("Email not matched!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (formData.password === "") {
      setFormError("Enter password!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (formData.password_confirmation === "") {
      setFormError("Enter confirmation password!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (formData.phone === "") {
      setFormError("Enter your phone number!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (formData.address1 === "") {
      setFormError("Enter address 1!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (formData.location_id === "") {
      setFormError("Enter your city!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else if (formData.postal_code === "") {
      setFormError("Enter postal code!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);

      return;
    } else {
      loginButton.classList.remove("btn-register");
      loginButton.classList.add("btn-disable-register");
      loader.style.display = "block";
      await axios
        .request({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/api/sanctum/csrf-cookie`,
        })
        .then(async (sanRep) => {
          await axios
            .request({
              method: "POST",
              url: `${process.env.REACT_APP_BASE_URL}/api/register`,
              data: formData,
            })
            .then(function (response) {
              dispacth(setUser(response.data.data));
              sessionStorage.setItem(
                "user",
                JSON.stringify(response.data.data)
              );
              localStorage.setItem("user", JSON.stringify(response.data.data));
              dispacth(resetAskSignup());
              loginButton.classList.remove("btn-disable-register");
              loginButton.classList.add("btn-register");
              loader.style.display = "block";
            })
            .catch(function (error) {
              setFormError(error.response.data.message);
              setTimeout(() => {
                setFormError(null);
              }, 5000);
              loginButton.classList.remove("btn-disable-register");
              loginButton.classList.add("btn-register");
              loader.style.display = "none";
              console.error(error);
            });
          loginButton.classList.remove("btn-disable-register");
          loginButton.classList.add("btn-register");
          loader.style.display = "none";
        });
    }
  };
  const cart = useSelector((state) => state.event.cart);
  const currentEvent = useSelector((state) => state.event.currentEvent);

  return (
    <div className="login-container">
      <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <h3 className="register-heading">Enter your contact details</h3>
          <h3 className="remaining-time">
            Time remaining : {props.timeRemaining}
          </h3>
          <p className="not-reserved">
            Tickets are not reserved until you have completed payment.
          </p>
          <form className="g-3" autoComplete="off">
            {formError && (
              <div className="alert alert-danger" role="alert">
                {formError}
              </div>
            )}
            <div className="row">
              <h3 className="register-sub-heading">Personal details</h3>
              <div className="col-6">
                <label htmlFor="first_name" className="form-label input-label">
                  First name<span className="field-required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control input-text-size"
                  name="first_name"
                  onChange={handleChange}
                  value={formData.first_name}
                  required={"required"}
                />
              </div>
              <div className="col-6">
                <label htmlFor="last_name" className="form-label input-label">
                  Last name<span className="field-required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control input-text-size"
                  name="last_name"
                  onChange={handleChange}
                  value={formData.last_name}
                  required={"required"}
                />
              </div>
              <div className="col-12">
                <label
                  htmlFor="staticEmail2"
                  className="form-label input-label"
                >
                  Email<span className="field-required">*</span>
                </label>
                <input
                  type="email"
                  className="form-control input-text-size"
                  name="email"
                  onChange={handleChange}
                  value={formData.email}
                  required={"required"}
                />
              </div>
              <div className="col-12">
                <label
                  htmlFor="confirm_email"
                  className="form-label input-label"
                >
                  Confirm Email<span className="field-required">*</span>
                </label>
                <input
                  type="email"
                  className="form-control input-text-size"
                  name="confirm_email"
                  onChange={handleChange}
                  value={formData.confirm_email}
                  required={"required"}
                />
              </div>
              <div className="col-12">
                <label className="form-label input-label">
                  Password<span className="field-required">*</span>
                </label>
                <input
                  type="password"
                  className="form-control input-text-size"
                  name="password"
                  onChange={handlePassword}
                  value={formData.password}
                  required={"required"}
                />
              </div>
              <span className="password-limit">
                Password must be atleast 6 chracter
              </span>
              <div className="col-12">
                <label className="form-label input-label">
                  Confirm Password<span className="field-required">*</span>
                </label>
                <input
                  type="password"
                  className="form-control input-text-size"
                  name="password_confirmation"
                  onChange={handlePasswordConfirmation}
                  value={formData.password_confirmation}
                  required={"required"}
                />
              </div>

              <div className="col-12">
                <label htmlFor="phone" className="form-label input-label">
                  Phone number<span className="field-required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control input-text-size"
                  name="phone"
                  onChange={handleChange}
                  value={formData.phone}
                  required={"required"}
                />
              </div>
            </div>
            <div className="row">
              <h3 className="mt-3 register-sub-heading">
                Billing address details
              </h3>
              <div className="col-12">
                <label htmlFor="address1" className="form-label input-label">
                  Address Line 1<span className="field-required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control input-text-size"
                  name="address1"
                  onChange={handleChange}
                  value={formData.address1}
                  required={"required"}
                />
              </div>
              <div className="col-12">
                <label htmlFor="address2" className="form-label input-label">
                  Address Line 2
                </label>
                <input
                  type="text"
                  className="form-control input-text-size"
                  name="address2"
                  onChange={handleChange}
                  value={formData.address2}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <label htmlFor="location_id" className="form-label input-label">
                  City<span className="field-required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control input-text-size"
                  name="location_id"
                  onChange={handleChange}
                  value={formData.location_id}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <label htmlFor="postal_code" className="form-label input-label">
                  Post code<span className="field-required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control input-text-size"
                  name="postal_code"
                  onChange={handleChange}
                  value={formData.postal_code}
                  required={"required"}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 text-center">
                <button
                  type="button"
                  className="btn btn-register mb-3"
                  onClick={registerUser}
                  //data-bs-dismiss="modal"
                >
                  Continue to checkout
                </button>
                <div id="registerloader" style={{ display: "none" }}>
                  <BeatLoader color="#C6153B" />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="event-align-middle">
            <h3 className="login-event-title">{currentEvent.name}</h3>
            <p className="ticket-waiting">
              {cart.productsCount}&nbsp;
              {cart.productsCount === 1 ? "ticket" : "tickets"}
            </p>
            <p className="inclusive-fee">
              All ticket prices are inclusive of booking fees
            </p>
            <p className="not-reserved">
              Tickets are not reserved until you have completed payment.
            </p>
            <h3 className="grand-total">£{cart.grandTotal.toFixed(2)}</h3>
            <button className="btn btn-back" onClick={gotoLogin}>
              Go back to login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventRegisterCheckoutForm;
