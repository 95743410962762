import Card from "./Card";
import ShowMore from "./ShowMore";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Divider from "./Divider";
import DefaultRoom from "../../gallery/default/placeholder.png";

var pillsimilar = null;
function Similar(props) {
  const [similar, setSimilar] = useState();

  useEffect(() => {
    fetchSimilar(props.item, props.page);
  }, [props.item, props.page]);
  async function fetchSimilar(item_id, page) {
    await axios
      .request({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/api/${page}`,
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
        params: {
          id: item_id,
          list: "similar",
        },
      })
      .then(function (response) {
        setSimilar(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  return (
    <>
      {similar && similar.length > 0 && (
        <div className="similar-container">
          <Divider />
          <div className="container">
            <div className="inner-content">
              <div className="row featured-business">
                <div className="col-12 text-center">
                  <h3 className="featured-business-title">Similar</h3>
                </div>
                {similar
                  ? similar.map((element, index) => {
                      let salary = false;
                      if (element.page === "business") {
                        pillsimilar = element.category;
                      } else if (element.page === "job") {
                        pillsimilar = element.type;
                        salary = element.salary_value;
                      } else if (element.page === "property") {
                        pillsimilar = element.property_type;
                        salary = element.amount;
                      } else {
                        pillsimilar = element.category;
                      }
                      if (element.page === "property") {
                        return (
                          <div
                            className="col-lg-4 col-md-6 col-sm-12"
                            key={`similar_${index}`}
                          >
                            <Card
                              align={"align-items-center"}
                              justifycontent={"center"}
                              image={
                                element.image_name
                                  ? element.image_path
                                  : DefaultRoom
                              }
                              isImage={
                                element.image_name ? element.image_path : false
                              }
                              title={element.name ? element.name : ""}
                              location={
                                element.location ? element.location : ""
                              }
                              postalcode={
                                element.postal_code ? element.postal_code : ""
                              }
                              page={element.page ? element.page : ""}
                              type={similar ? pillsimilar : ""}
                              salary={salary}
                              desc={element.detail ? element.detail : ""}
                              detail_url={`/${element.page}/${element.slug}`}
                              views={element.views}
                            />
                          </div>
                        );
                      } else if (element.page === "business") {
                        return (
                          <div
                            className="col-lg-4 col-md-6 col-sm-12"
                            key={`similar_${index}`}
                          >
                            <Card
                              title={element.name ? element.name : ""}
                              location={
                                element.location ? element.location : ""
                              }
                              postalcode={
                                element.postal_code ? element.postal_code : ""
                              }
                              page={element.page ? element.page : ""}
                              type={similar ? pillsimilar : ""}
                              salary={salary}
                              desc={element.detail ? element.detail : ""}
                              category_id={element.category_id}
                              detail_url={`/${element.page}/${element.slug}`}
                              views={element.views}
                            />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="col-lg-4 col-md-6 col-sm-12"
                            key={`similar_${index}`}
                          >
                            <Card
                              title={element.name ? element.name : ""}
                              location={
                                element.location ? element.location : ""
                              }
                              postalcode={
                                element.postal_code ? element.postal_code : ""
                              }
                              page={element.page ? element.page : ""}
                              type={similar ? pillsimilar : ""}
                              salary={salary}
                              desc={element.detail ? element.detail : ""}
                              detail_url={`/${element.page}/${element.slug}`}
                              views={element.views}
                            />
                          </div>
                        );
                      }
                    })
                  : false}
                <ShowMore
                  url={`/search?category=${props.category}&location=${props.location}&page=1&limit=10`}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Similar;
