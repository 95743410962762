import React, { useEffect, useState } from "react";
import Divider from "../common/Divider";
import { Helmet } from "react-helmet-async";
import PopularCategories from "../common/PopularCategories";
import AdvertiseWithUs from "../template/AdvertiseWithUs";
import axios from "axios";
import { BeatLoader } from "react-spinners";

export default function Terms(props) {
  const [data, setData] = useState();

  const fetchData = async () => {
    await axios
      .request({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/api/pages/terms-conditions`,
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
      })
      .then(function (response) {
        setData(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <Helmet>
        <title>{`Sahi Search - ${props.pageTitle}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="container">
        <div className="row">
          {!data && (
            <div className="loader-in-component-body">
              <BeatLoader color="#C6153B" />
            </div>
          )}
          {data && (
            <div className="col-12 ">
              <div className="content-header text-center">
                <h1 className="content-heading">{data.name}</h1>
              </div>
              <p
                className="content"
                dangerouslySetInnerHTML={{
                  __html: data.detail
                    ? data.detail.replace(/\n/g, "<br />")
                    : "",
                }}
              />
            </div>
          )}
        </div>
      </div>
      <Divider />
      <PopularCategories />
      <Divider />
      <AdvertiseWithUs />
    </div>
  );
}
