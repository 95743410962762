import React, { useEffect, useState } from "react";
import Card from "../common/Card";
import ShowMore from "../common/ShowMore";
import axios from "axios";
import DefaultRoom from "../../gallery/default/placeholder.png";

function FeaturedProperty() {
  const [data, setData] = useState();

  useEffect(() => {
    async function fetchData() {
      const options = {
        method: "GET",
        url: process.env.REACT_APP_BASE_URL + "/api/property",
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
        params: {
          list: "featured",
        },
      };

      await axios
        .request(options)
        .then(function (response) {
          setData(response.data.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    }
    fetchData();
  }, []);

  /* console.log(data) */
  return (
    <div className="container">
      <div className="inner-content">
        <div className="row rooms-for-rent">
          <div className="col-12 text-center">
            <h3 className="featured-business-title">Rooms for Rent</h3>
          </div>
          {data
            ? data.map((element, index) => {
                return (
                  <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
                    <Card
                      /* imgaeWidth='345px'
                                    imageHeight='212px' */
                      align={"align-items-center"}
                      justifycontent={"center"}
                      image={
                        element.image_name ? element.image_path : DefaultRoom
                      }
                      isImage={element.image_name ? element.image_path : false}
                      title={element.name ? element.name : ""}
                      location={element.location ? element.location : ""}
                      postalcode={
                        element.postal_code ? element.postal_code : ""
                      }
                      page={element.page ? element.page : ""}
                      type={
                        element.property_type
                          ? element.property_type.charAt(0).toUpperCase() +
                            element.property_type.slice(1)
                          : ""
                      }
                      salary={element.amount ? element.amount : false}
                      desc={element.detail ? element.detail : ""}
                      detail_url={element.page + "/" + element.slug}
                      views={element.views}
                    />
                  </div>
                );
              })
            : false}
          <ShowMore url={"/search?category=3&location=1&page=1&limit=10"} />
        </div>
      </div>
    </div>
  );
}
export default FeaturedProperty;
