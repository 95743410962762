import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  distance: 0,
  remainingTime: "",
};

export const timerSlice = createSlice({
  name: "timer",
  initialState,
  reducers: {
    setTimer: (state) => {
      var countDownDate = new Date().getTime() + 1000 * 60 * 15;
      var x = setInterval(function () {
        var now = new Date().getTime();
        const dist = countDownDate - now;
        var minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((dist % (1000 * 60)) / 1000);
        const rT = minutes + " : " + seconds;

        if (dist <= 1) {
          clearInterval(x);
          state.distance = 0;
          state.remainingTime = null;
        } else {
          state.distance = dist;
          state.remainingTime = rT;
        }
      }, 1000);
    },
    resetTimer: (state) => {
      state.distance = 0;
      state.remainingTime = null;
    },
  },
});

export const { setTimer, resetTimer } = timerSlice.actions;

export default timerSlice.reducer;
