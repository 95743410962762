import React from "react";
import Category from "../common/Category";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

function BusinessSearch(props) {
  const handleCategoryClick = (event) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  let queryLocation = 1;
  if (queryParams.get("location")) {
    queryLocation = queryParams.get("location");
  }
  return (
    <>
      <Helmet>
        <title>{`Sahi Search - ${props.pageTitle}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="card category-list hidden-xs hidden-sm">
        <div className="card-body ">
          <h6 className="category-list-title">All categories</h6>
          <ul className="list-unstyled">
            {props.searchCategories
              ? props.searchCategories.map((element, index) => {
                  return (
                    <li
                      className="category-list-item"
                      key={"category_" + index}
                    >
                      <Category
                        active={
                          parseInt(props.queryCategory) === parseInt(element.id)
                            ? 1
                            : 2
                        }
                        categoryId={element.id}
                        category={element.name}
                        count={element.count}
                        to={`/search?category=${element.id}&location=${queryLocation}&page=1&limit=10`}
                        onclick={handleCategoryClick}
                        key={"category_" + index}
                      />
                    </li>
                  );
                })
              : false}
          </ul>
        </div>
      </div>
    </>
  );
}
export default BusinessSearch;
