import React from "react";
import SelectCustom from "../common/SelectCustom";
import { Helmet } from "react-helmet-async";
import { useHistory, useLocation } from "react-router-dom";

export default function BusinessSearchModel(props) {
  const history = useHistory();
  //using location hook to get params from URL
  const location = useLocation();

  let queryParams = new URLSearchParams(location.search);
  let queryCategory = queryParams.get("category");
  let queryLocation = queryParams.get("location");
  let queryPage = queryParams.get("page");
  let queryLimit = queryParams.get("limit");
  let querySort = queryParams.get("sort");

  function handleDropDown(event, type) {
    querySort = event.target.value;
  }
  const handleSubmit = () => {
    queryCategory = document.getElementById("category_select").title;
    queryLocation = document.getElementById("location_select").title;

    history.push(
      `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}`
    );
  };
  return (
    <>
      <Helmet>
        <title>{`Sahi Search - ${props.pageTitle}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Refine Search
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="col-lg-12 col-md-12 mb-2">
                <label className="form-label modal-filter-label">
                  Search category
                </label>
                <SelectCustom
                  className={"modal-filter"}
                  id={"category_select"}
                  options={props.categories ? props.categories : false}
                  //handle={props.handleRedirect}
                  type="category"
                  selectedOption={props.categorySelectedOption}
                />
              </div>
              <div className="col-lg-12 col-md-12 mb-2">
                <label className="form-label modal-filter-label">
                  Location (Town)
                </label>
                <SelectCustom
                  className={"modal-filter"}
                  id={"location_select"}
                  options={props.locations ? props.locations : false}
                  type="location"
                  //handle={props.handleRedirect}
                  selectedOption={props.locationSelectedOption}
                />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                <label className="form-label modal-filter-label">Sort by</label>
                <select
                  defaultValue={querySort}
                  onChange={(event) => handleDropDown(event)}
                  className="form-select modal-filter"
                >
                  <option value="null">Relevance</option>
                  <option value="name">A - Z</option>
                </select>
              </div>
            </div>
            <div className="modal-footer align-content-center justify-content-center">
              <button
                type="button"
                className="btn btn-advertise-light hidden-xxs"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-advertise-danger"
                data-bs-dismiss="modal"
                onClick={handleSubmit}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
