import React, { useState, useEffect } from 'react';
import {ChevronLeft} from 'react-bootstrap-icons'
import {ChevronRight} from 'react-bootstrap-icons'

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const [visiblePages, setVisiblePages] = useState([]);

  const handlePageChange = (event,page) => {
    onPageChange(event,page);
  };
  const handleFocus=(page)=>{
    var ele = document.getElementById(`anchor_${page}`)
    ele.blur();
  }

  const generateVisiblePages = () => {
    let pages = [];

    if (totalPages <= 5) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      const firstPage = currentPage > 2 ? currentPage - 1 : 1;
      const lastPage = currentPage < totalPages - 3 ? currentPage + 3 : totalPages;
      if(currentPage > 2)
      {
      pages.push(1);
      }

      if (firstPage > 2) {
        pages.push('...');
      }

      for (let i = firstPage; i <= lastPage; i++) {
        pages.push(i);
      }

      if (lastPage < totalPages - 1) {
        pages.push('...');
      }
      pages.push(totalPages);
    }
  const newPages = removeDuplicates(pages);
    
    setVisiblePages(newPages);
  };
  function removeDuplicates(arr) {
    return arr.filter((value, index, self) => {
      return typeof value !== 'number'|| self.indexOf(value) === index;
    });
  }

  useEffect(() => {
    generateVisiblePages();
  }, [totalPages, currentPage]);

  return (
    <nav aria-label="Page navigation">
        <ul className="pagination justify-content-center">
          
        <li className={"page-item"}>
            <a
            onClick={(event) => handlePageChange(event, currentPage - 1)}
            href="/"
            className={"page-link-arrow"}
            style={parseInt(currentPage) === parseInt(1)?{pointerEvents:'none',cursor:'default',opacity:'25%'}:{}}>
                <ChevronLeft/>
            </a>
        </li>
     

      {visiblePages.map((page, index) => (
        <li className={"page-item"} key={"page_" + index}>
            <a onClick={(event) => handlePageChange(event,page)}
            onFocus={()=>handleFocus(page)}
            href="/"
            id={`anchor_${page}`}
            className={
                (currentPage === page)
                ? "page-link disabled"
                : "page-link"
            }
            style={page === '...'?{pointerEvents:'none',cursor:'default', opacity:'45%'}:{}}>
                {page}
            </a>
        </li>
      ))}
      
        <li className={"page-item"}>
            <a
            onClick={(event) => handlePageChange(event,currentPage + 1)}
            href="/"
            className={"page-link-arrow"}
            style={parseInt(currentPage) === parseInt(totalPages)?{pointerEvents:'none',cursor:'default',opacity:'25%'}:{}}>
                <ChevronRight/>
            </a>
        </li>
      
      </ul>
    </nav>
  );
};

export default Pagination;
