import ShowMore from "../common/ShowMore";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Divider from "../common/Divider";
import EventCard from "./EventCard";

function EventSimilar(props) {
  const [similar, setSimilar] = useState();

  useEffect(() => {
    fetchSimilar(props.item, props.page);
  }, [props.item, props.page]);
  async function fetchSimilar(item_id, page) {
    await axios
      .request({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/api/${page}`,
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
        params: {
          id: item_id,
          list: "similar",
        },
      })
      .then(function (response) {
        setSimilar(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  return (
    <>
      {similar && similar.length > 0 && (
        <div className="similar-container">
          <Divider />
          <div className="container">
            <div className="inner-content">
              <div className="row featured-business">
                <div className="col-12 text-center">
                  <h3 className="featured-business-title">Similar</h3>
                </div>
                {similar
                  ? similar.map((element, index) => {
                      return (
                        <div
                          className="col-lg-4 col-md-6 col-sm-12"
                          key={`similar_${index}`}
                        >
                          <EventCard
                            imageHeight="370px"
                            image={
                              element.image_name ? element.image_path : false
                            }
                            title={element.name ? element.name : ""}
                            address1={element.address1 ? element.address1 : ""}
                            location={element.location ? element.location : ""}
                            postalcode={
                              element.postal_code ? element.postal_code : ""
                            }
                            page={element.page ? element.page : ""}
                            type={
                              element.category
                                ? element.category.charAt(0).toUpperCase() +
                                  element.category.slice(1)
                                : ""
                            }
                            startTicketDate={element.ticket_start_at}
                            startDate={element.start_date}
                            showStartDate={element.show_start_date}
                            endDate={element.end_date}
                            adultOnly={element.adult_event}
                            detail_url={`/${element.page}/${element.slug}`}
                            price={
                              element.tickets.length > 0
                                ? element.tickets[0].price
                                : null
                            }
                            views={element.views}
                          />
                        </div>
                      );
                    })
                  : false}
                <ShowMore
                  url={`/search?category=${props.category}&location=${props.location}&page=1&limit=10`}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default EventSimilar;
