import React from "react";
import { Search } from "react-bootstrap-icons";
import { Helmet } from "react-helmet-async";

export default function JobSearch(props) {
  function handleDropDown(event, type) {
    var selectedValue = event.target.value;
    props.handle(selectedValue, type);
  }
  function handleInput() {
    var selectedValue = document.getElementById("searchTitle").value;
    props.handle(selectedValue, "title");
  }
  function handleCheck(event, paramType) {
    if (event.target.checked) {
      event.target.checked = true;
      props.handle(1, paramType);
    } else {
      event.target.checked = false;
      props.handle(0, paramType);
    }
  }

  return (
    <div className="property-search hidden-xs hidden-sm">
      <Helmet>
        <title>{`Sahi Search - ${props.pageTitle}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
          <label className="form-label">Job Title</label>
          <div className="input-group mb-3">
            <input
              id="searchTitle"
              type="text"
              className="form-control"
              placeholder="Enter a job title"
              aria-describedby="button-addon1"
              defaultValue={props.title ? props.title : ""}
            />
            <button
              onClick={handleInput}
              className="btn btn-outline-secondary btn-small-danger"
              type="button"
              id="button-addon1"
            >
              <Search />
            </button>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
          <label className="form-label">Type of Job</label>
          <select
            defaultValue={props.job}
            onChange={(event) => handleDropDown(event, "job")}
            className="form-select"
          >
            <option value="0">All</option>
            <option value="1">Full Time</option>
            <option value="2">Part Time</option>
          </select>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
          <label className="form-label">Level</label>
          <select
            defaultValue={props.level}
            onChange={(event) => handleDropDown(event, "level")}
            className="form-select"
          >
            <option value="0">All</option>
            <option value="1">Beginner</option>
            <option value="2">Intermediate</option>
            <option value="3">Expert</option>
          </select>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
          <label className="form-label">Sort by</label>
          <select
            defaultValue={props.sort}
            onChange={(event) => handleDropDown(event, "sort")}
            className="form-select"
          >
            <option value="relevance">Relevance</option>
            <option value="name">A - Z</option>
          </select>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <hr className="section-divider" />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row radio-filter">
            <div className="col-lg-9 col-md-9 col-9">
              <label className="form-check-label">
                Hide jobs with undisclosed salary
              </label>
            </div>
            <div className="col-lg-3 col-md-3 col-3">
              <div className="form-check form-switch">
                <input
                  checked={
                    parseInt(props.disclosed) === parseInt(1) ? true : false
                  }
                  onChange={(event) => handleCheck(event, "disclosed")}
                  className="form-check-input"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <hr className="section-divider" />
        </div>
      </div>
    </div>
  );
}
