import React, { useEffect, useState } from "react";
import BannerImage from "../../gallery/banner.jpg";
import { Search } from "react-bootstrap-icons";
import SelectCustom from "./SelectCustom";
import axios from "axios";
import { useHistory } from "react-router-dom";
function Banner() {
  let categorySelectedOption = {
    label: "What are you looking for?",
    value: 1,
  };
  let locationSelectedOption = {
    label: "Chose your location",
    value: 1,
  };
  const [search, setSearch] = useState({ categories: null, locations: null });

  useEffect(() => {
    fetchData();
  }, []);
  const history = useHistory();
  async function fetchData() {
    await axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_BASE_URL + "/api/categories",
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
        params: {
          page: 1,
          limit: 300,
        },
      })
      .then(function (response) {
        setSearch((prevSearch) => ({
          ...prevSearch,
          categories: response.data.data,
        }));
      })
      .catch(function (error) {
        console.error(error);
      });

    await axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_BASE_URL + "/api/locations",
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
        params: {
          page: 1,
          limit: 300,
        },
      })
      .then(function (response) {
        setSearch((prevSearch) => ({
          ...prevSearch,
          locations: response.data.data,
        }));
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  const handleRedirect = (event) => {
    event.preventDefault();
    let category_id = 1; //document.getElementById('category_select').title;
    let location_id = 1; //document.getElementById('location_select').title;
    var catSelect = document.getElementById("category_select");
    if (catSelect.title !== "") {
      category_id = catSelect.title;
    }

    var locSelect = document.getElementById("location_select");
    if (locSelect.title !== "") {
      location_id = locSelect.title;
    }
    history.push(`/search?category=${category_id}&location=${location_id}&page=1&limit=10`);
  };

  return (
    <>
      <div className="container-fluid">
        <section className="hero hero--video">
          <div className="container">
            <div className="col-12">
              <div className="search">
                <div className="d-flex justify-content-center">
                  <h6 className="banner-text-1">जे खोजिन्छ, मज्जाले भेटिन्छ</h6>
                </div>
                <div className="d-flex justify-content-center">
                  <h6 className="banner-text-2">The right way to find the localised Nepalese services in the UK</h6>
                </div>
                <div className="row d-flex justify-content-center">
                  {search && search.categories && (
                    <div className="col-lg-3 col-md-3 col-12 category-select">
                      <SelectCustom
                        id={"category_select"}
                        //placeHolder='e.g. Plumber, Electrician'
                        options={search ? search.categories : false}
                        selectedOption={categorySelectedOption}
                      />
                    </div>
                  )}
                  {search && search.locations && (
                    <div className="col-lg-3 col-md-3 col-12 location-select">
                      <SelectCustom
                        id={"location_select"}
                        //placeHolder='Town or City'
                        options={search ? search.locations : false}
                        selectedOption={locationSelectedOption}
                      />
                    </div>
                  )}
                  <div className="col-lg-2 col-md-2 col-12">
                    <button onClick={handleRedirect} className="btn-search-danger">
                      <Search size={15} style={{ marginRight: "5px" }} />
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img className="d-lg-block" src={BannerImage} alt="Banner" style={{ opacity: "0.3" }} />
        </section>
      </div>
    </>
  );
}
export default Banner;
