import { createSlice } from "@reduxjs/toolkit";
let user = null;
user = JSON.parse(sessionStorage.getItem("user"));
const initialState = {
  user: user,
  askSignup: false,
  guest: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, actions) => {
      state.user = actions.payload;
    },
    setSignup: (state) => {
      state.askSignup = true;
    },
    resetAskSignup: (state) => {
      state.askSignup = false;
    },
    setGuest: (state) => {
      state.guest = true;
    },
    resetGuest: (state) => {
      state.guest = false;
    },
    logoutUser: (state) => {
      state.user = null;
      state.guest = false;
      state.askSignup = false;
    },
  },
});

export const { setUser, setSignup, resetAskSignup, setGuest, resetGuest, logoutUser } = userSlice.actions;

export default userSlice.reducer;
