import React from "react";
import Select from "react-select";
import { useState, useEffect } from "react";
function SelectCustom(props) {
  let selectOptions = [];
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (props.handle && props.type) {
      props.handle(selectedOption.value, props.type);
    }
    if (props.onChange) {
      props.onChange(selectedOption.value, selectedOption.label);
    }
  };

  useEffect(() => {
    if (selectedOption === null && props.selectedOption) {
      setSelectedOption(props.selectedOption);
    } else if (selectedOption && props.selectedOption) {
      if (
        parseInt(props.selectedOption.value) !== parseInt(selectedOption.value)
      ) {
        setSelectedOption(props.selectedOption);
      }
    }
  }, [props.selectedOption]);
  if (props.options) {
    selectOptions = props.options.map((element, index) => {
      return {
        label: element.name,
        value: element.id,
      };
    });
  }
  return (
    <div
      id={props.id ? props.id : ""}
      title={selectedOption ? selectedOption.value : ""}
    >
      <Select
        {...props}
        className={props.className ? props.className : ""}
        key={props.id ? props.id : ""}
        value={selectedOption}
        options={selectOptions}
        onChange={handleChange}
      />
    </div>
  );
}
export default SelectCustom;
