import React from "react";
import Divider from "../common/Divider";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useLocation, useHistory } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { initializeTagManager } from "../../TagManager";
import { Helmet } from "react-helmet-async";
import PopularCategories from "../common/PopularCategories";
import { BeatLoader } from "react-spinners";
import axios from "axios";

export default function Contact(props) {
  const [data, setData] = useState();

  const fetchData = async () => {
    await axios
      .request({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/api/pages/contact-us`,
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
      })
      .then(function (response) {
        setData(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  const reCaptchaRef = useRef(null);
  function toggleMobilenav() {
    var body = document.body;
    var hasClass = body.classList.contains("nav2-is-toggled");
    if (hasClass) {
      console.log(hasClass);
      document.body.classList.remove("nav2-is-toggled");
    }
  }

  const history = useHistory();
  const location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  let querytype = queryParams.get("type");
  let queryUrl = queryParams.get("url");
  const [reportURL, setReportURL] = useState();

  useEffect(() => {
    initializeTagManager();
    toggleMobilenav();
    fetchData();
    if (queryUrl) {
      setReportURL(queryUrl);
      history.replace(`/contact?type=${querytype}`);
    }
  }, [history]);

  const MySwal = withReactContent(Swal);

  const SERVICE_ID = "service_af5k34d";
  const TEMPLATE_ID = "template_ok8fo2p";
  const USER_ID = "FUeYBGC4f4koctznd";

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (!recaptchaValue) {
      MySwal.fire({
        icon: "error",
        title: "Verify ReCAPTCHA!",
      });
    } else {
      const token = reCaptchaRef.current.getValue();
      if (token) {
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
          (result) => {
            setReportURL("");
            console.log(result.text);
            MySwal.fire({
              icon: "success",
              title: "Message Sent Successfully",
            });
            e.target.reset();
            reCaptchaRef.current.reset();
          },
          (error) => {
            console.log(error.text);
            MySwal.fire({
              icon: "error",
              title: "Ooops, something went wrong",
              text: error.text,
            });
            reCaptchaRef.current.reset();
          }
        );
      }
    }
    /* try {
            // Sending secret key and response token to Google Recaptcha API for authentication.
            const response = await axios.post(
              `https://www.google.com/recaptcha/api/siteverify?secret=${'6LeHTkUmAAAAAFxbHiMR4hGGCclQqgXwwzcQ8h44'}&response=${token}`
            );
        
            // Check response status and send back to the client-side
            if (response.data.success) {

                

            } else {
                reCaptchaRef.current.reset();
            }
          } catch (error) {
            // Handle any errors that occur during the reCAPTCHA verification process
            console.error(error);
           } */
  };

  return (
    <>
      <Helmet>
        <title>{`Sahi Search - ${props.pageTitle}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="container contact-container">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-12 hidden-sm hidden-xs hidden-xxs">
            <div className="row">
              {!data && (
                <div className="loader-in-component-body">
                  <BeatLoader color="#C6153B" />
                </div>
              )}
              {data && (
                <div className="col-12">
                  <p
                    style={{ marginTop: "200px" }}
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: data.detail
                        ? data.detail.replace(/\n/g, "<br />")
                        : "",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-12 ">
            <div className="contact-header text-center">
              <h6 className="contact-heading-1">Contact us</h6>
              <h1 className="contact-heading-2">Get in touch</h1>
              <p className="contact-compliment">
                We’d love to hear from you. Please fill out this form.
              </p>
              {data && (
                <p
                  className="content hidden-md"
                  dangerouslySetInnerHTML={{
                    __html: data.detail
                      ? data.detail.replace(/\n/g, "<br />")
                      : "",
                  }}
                />
              )}
            </div>
            <div className="contact-form">
              <form id="contact_from" onSubmit={handleOnSubmit}>
                <div className="row">
                  <div className="mb-3 col-lg-6 col-md-12 col-12">
                    <label
                      htmlFor="inputFirstName"
                      className="form-label input-label"
                    >
                      First name
                    </label>
                    <input
                      required
                      name="first_name"
                      type="text"
                      className="form-control"
                      id="inputFirstName"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="mb-3 col-lg-6 col-md-12 col-12">
                    <label
                      htmlFor="inputLastName"
                      className="form-label input-label"
                    >
                      Last name
                    </label>
                    <input
                      required
                      name="last_name"
                      type="text"
                      className="form-control"
                      id="inputLastName"
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="mb-3 col-lg-12 col-md-12 col-12">
                    <label
                      htmlFor="inputEmail"
                      className="form-label input-label"
                    >
                      Email
                    </label>
                    <input
                      required
                      name="email"
                      type="email"
                      className="form-control"
                      id="inputEmail"
                      placeholder="you@company.com"
                    />
                  </div>
                  <div className="mb-3 col-lg-12 col-md-12 col-12">
                    <label
                      htmlFor="inputSelect"
                      className="form-label input-label"
                    >
                      Subject
                    </label>
                    <select
                      required
                      name="subject"
                      className="form-select"
                      aria-label=""
                    >
                      <option
                        defaultValue={
                          querytype && querytype === "general"
                            ? "General enquiry"
                            : ""
                        }
                        //selected ={querytype && querytype === 'general'?'selected':''}
                        disabled={
                          querytype && querytype !== "general" ? "disable" : ""
                        }
                        value="General enquiry"
                      >
                        General enquiry
                      </option>
                      <option
                        defaultValue={
                          querytype && querytype === "advertise"
                            ? "Advertise with us"
                            : ""
                        }
                        //selected ={querytype && querytype === 'advertise'?'selected':''}
                        disabled={
                          querytype && querytype !== "advertise"
                            ? "disable"
                            : ""
                        }
                        value="Advertise with us"
                      >
                        Advertise with us
                      </option>
                      <option
                        defaultValue={
                          querytype && querytype === "event"
                            ? "Register event"
                            : ""
                        }
                        //selected ={querytype && querytype === 'advertise'?'selected':''}
                        disabled={
                          querytype && querytype !== "event" ? "disable" : ""
                        }
                        value="Register event"
                      >
                        Register event
                      </option>
                      <option
                        defaultValue={reportURL ? "Report a listing" : ""}
                        //selected ={querytype && querytype === 'report'?'selected':''}
                        disabled={
                          querytype && querytype !== "report" ? "disable" : ""
                        }
                        value="Report a listing"
                      >
                        Report a listing
                      </option>
                    </select>
                  </div>
                  <div className="mb-3 col-lg-12 col-md-12 col-12">
                    <label
                      htmlFor="inputPhoneNumber"
                      className="form-label input-label"
                    >
                      Phone number
                    </label>
                    <div className="input-group">
                      <select className="input-group-text" aria-label="">
                        <option value="1">UK</option>
                      </select>
                      <input
                        required
                        name="phone"
                        type="text"
                        className="form-control"
                        placeholder="0 1234 123456"
                      />
                    </div>
                  </div>
                  <div className="mb-4 col-lg-12 col-md-12 col-12">
                    <label
                      htmlFor="inputTeaxtArea"
                      className="form-label input-label"
                    >
                      Meassage
                    </label>

                    <textarea
                      required
                      defaultValue={reportURL ? reportURL : ""}
                      name="message"
                      className="form-control"
                      rows="5"
                      aria-label="With textarea"
                      placeholder="Leave us a message..."
                    ></textarea>
                  </div>
                  <div className="mb-4 col-lg-12 col-md-12 col-12">
                    <ReCAPTCHA
                      required
                      onChange={handleRecaptchaChange}
                      sitekey="6LfwbzIiAAAAAEh28SsjEuhN4RYqpJmjAyCXmOFN"
                      ref={reCaptchaRef}
                    />
                  </div>
                  <button type="submit" className="btn btn-contact-danger">
                    Send message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <PopularCategories />
      <Divider />
    </>
  );
}
