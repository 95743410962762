import React, { useEffect, useState } from "react";
import { Alarm, Calendar, Download, GeoAlt } from "react-bootstrap-icons";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { setActiveLink } from "../../app/features/nav/navSlice";
import { useDispatch } from "react-redux";

function EventSuccess(props) {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [eventLocation, setEventLocation] = useState();
  const location = useLocation();
  const substrings = location.pathname.split("/");
  const bookingSlug = substrings["2"];
  useEffect(() => {
    dispatch(setActiveLink("event"));
    fetchData();
  }, []);
  const goToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  async function fetchData() {
    await axios
      .request({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/api/bookings/${bookingSlug}`,
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
      })
      .then(async function (response) {
        setData(response.data.data);
        const locationId = response.data.data.event.location_id;
        await axios
          .request({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/api/locations/${locationId}`,
            headers: {
              Authorization: process.env.REACT_APP_API_KEY,
            },
          })
          .then(function (response) {
            setEventLocation(response.data.data);
          })
          .catch(function (error) {
            console.error(error);
          });
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  const dateFormat = (d, type = 0) => {
    let date = new Date(d);
    const dayOfWeek = date.toLocaleString("en-US", { weekday: "short" });
    var month = date.toLocaleString("en-US", { month: "short" });
    const day = date.getDate();

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;
    let formattedDate = "";
    if (type === 2) {
      formattedDate = `${hours}:${minutes < 10 ? "0" + minutes : minutes}${ampm}`;
    } else if (type === 3) {
      formattedDate = `${day} ${month}`;
    } else {
      formattedDate = `${dayOfWeek}, ${day} ${month}, ${hours}:${minutes < 10 ? "0" + minutes : minutes}${ampm}`;
    }

    return formattedDate;
  };
  return (
    <>
      <Helmet>
        <title>{`Sahi Search - ${props.pageTitle}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="container">
        <div className="inner-content">
          <div className="row ticket-booked-coontainer">
            <div className="col-lg-3 col-md-2 hidden-xs"></div>
            <div className="col-lg-6 col-md-8 col-12 ">
              {!data && (
                <div className="loader-in-component-body">
                  <BeatLoader color="#C6153B" onLoad={goToTop} />
                </div>
              )}
              {data && (
                <>
                  <div className="col-12">
                    {console.log(data.items.length, "ticket length")}
                    {parseInt(data.items.length) > 1 && <h3 className="ticket-booked-title">Tickets booked!</h3>}
                    {parseInt(data.items.length) === 1 && <h3 className="ticket-booked-title">Ticket booked!</h3>}
                    <h3 className="ticket-booked-event-title">{data.event.name}</h3>
                  </div>
                  <div className=" col-12 ticket-detail-area">
                    <p className="location-on-detail paragraph-margin-sm">
                      <GeoAlt size={13} color="#4E5BA6" className="me-1"/>
                      {eventLocation ? (eventLocation.name ? eventLocation.name : "") : ""} {data.event.postal_code}
                    </p>
                    <p className="location-on-detail me-1">
                      <Calendar size={13} color="#4E5BA6"  className="me-1"/>
                      {dateFormat(data.event.start_date)} - {dateFormat(data.event.end_date)}
                    </p>
                    {data.last_entry_time &&
                    <p className="location-on-detail me-1">
                      <Alarm size={13} color="#4E5BA6" className="me-1"/>
                      Doors close at {dateFormat(data.last_entry_time,1)}
                    </p>
                    }
                  </div>
                  <div className="col-12">
                    <p className="mailed-to">
                      Tickets emailed to <span className="mail-to-text">{data.email}</span>
                    </p>
                    <p className="mail-to-text">Please check spam if you do not find them in your inbox.</p>
                  </div>
                  <div className="col-12 text-center qrcode-container">
                    <img src={data.QRCode} alt="QrCode" />
                  </div>
                  <div className="ticket-button-area row">
                    <div className="col-12 mt-3">
                      {/* <button className="btn btn-save-ticket">
                        <Download /> Save ticket as image
                      </button> */}
                      <a href={`https://sahisearch.com/manage/public/get_ticket/${data.slug}/pdf`} target="_blank" rel="noopener noreferrer" className="btn-save-ticket">
                        <Download /> Save ticket as PDF
                      </a>
                    </div>
                    <div className="col-12 mt-3">
                      <a href={`https://sahisearch.com/manage/public/get_ticket/${data.slug}/view`} target="_blank" rel="noopener noreferrer" className="btn-view-tickets">
                        View all tickets
                      </a>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-3 col-md-2 hidden-xs"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventSuccess;
