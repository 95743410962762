import React, { useEffect, useState } from "react";
import FeaturedBusiness from "../business/FeaturedBusiness";
import HomeFeaturedEvents from "../events/HomeFeaturedEvents";
import axios from "axios";
import HomeFeaturedEventsSmallScreen from "../events/HomeFeaturedEventsSmallScreen";
function TopFeatured() {
  const [events, setEvents] = useState();
  const [exchangeRate, setExchangeRate] = useState();

  useEffect(() => {
    fetchData();
    getExhcangeRate();
  }, []);
  async function fetchData() {
    const options = {
      method: "GET",
      url: process.env.REACT_APP_BASE_URL + "/api/events",
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
      },
      params: {
        list: "featured",
      },
    };

    await axios
      .request(options)
      .then(function (response) {
        setEvents(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  async function getExhcangeRate() {
    await axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_BASE_URL + "/api/advertisements",
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
      })
      .then(function (response) {
        setExchangeRate(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 hidden-md">
          <HomeFeaturedEventsSmallScreen
            events={events}
            exchangeRate={exchangeRate}
          />
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
          <FeaturedBusiness />
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 /* hidden-sm hidden-xs hidden-xxs */">
          <HomeFeaturedEvents events={events} exchangeRate={exchangeRate} />
        </div>
      </div>
    </div>
  );
}

export default TopFeatured;
