import React from "react";
import { Link } from "react-router-dom";
function AdvertiseWithUs() {
  function goToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <>
      <div className="container advertise-container">
        <div className="inner-content">
          <div className="row advertise-with-us text-center">
            <h1 className="advertise-heading ">
              Start listing with us for free
            </h1>
            <p className="advertise-desc">
              Directly reach out to the Nepalese community in the UK
            </p>
            <div className="col-lg-3 col-md-3 col-sm-3"></div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              {/* <a href='/' className='btn-advertise-light btn btn-light btn-sm'>Learn more</a> */}
              <Link
                to={"/contact?type=general"}
                className="btn-advertise-danger btn"
                onClick={goToTop}
              >
                Contact us
              </Link>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3"></div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AdvertiseWithUs;
