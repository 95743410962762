import React from "react";

export default function CustomAdd({ exchangeRate }) {
  return (
    <>
      {exchangeRate &&
        exchangeRate.map((element, index) => {
          return (
            <div className=" col-12 card-holder">
              <div className="card card-money-exchange">
                <div className="card-body card-body-money-exchange">
                  <div className="row">
                    <div className="col-2">
                      <img
                        className="img-fluid"
                        src={element.image_path}
                        alt={element.name}
                      />
                    </div>
                    <div className="col-10">
                      <h1 className="live-exchange-rate-text">
                        {element.name}
                      </h1>
                      {/* <h1 className="live-exchange-rate-text">Peepal Has Your Back</h1> */}
                    </div>
                  </div>

                  <p className="live-exchange-rate">{element.detail}</p>
                  <a
                    href={element.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-send-money"
                  >
                    Read more
                  </a>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}
