import React, { useState } from "react";
import EventsTab from "./EventsTab";
import SelectCustom from "../common/SelectCustom";
import { GeoAlt } from "react-bootstrap-icons";

function UpcomingEvents({ locations }) {
  let locationSelectedOption = {
    label: "Everywhere",
    value: 1,
  };
  const [location, setLocation] = useState(locationSelectedOption.value);

  const showSelect = () => {
    var select = document.getElementById("locationSelec");
    select.style.display = "block";
    var LocationShow = document.getElementById("locationShow");
    LocationShow.style.display = "none";
    var myButton = document.getElementById("eventChangeLocation");
    myButton.style.display = "none";
  };
  const onLocationSelect = (location_id, location) => {
    var select = document.getElementById("locationSelec");
    select.style.display = "none";
    var LocationShow = document.getElementById("locationShow");
    LocationShow.style.display = "block";
    LocationShow.innerHTML = `<h3 className="location-show">${location}</h3>`;
    var myButton = document.getElementById("eventChangeLocation");
    myButton.style.display = "block";
    locationSelectedOption.label = location;
    locationSelectedOption.value = location_id;
    setLocation(location_id);
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
          <h6 className="upcoming-events-title">Upcoming events</h6>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="upcoming-events-location">
            <div className="row justify-content-center">
              <div className="col-auto hidden-xs hidden-xxs">
                <GeoAlt size={30} color="#667085" />
              </div>
              {locations && (
                <div className="col-auto location-select text-center">
                  <div id={"locationSelec"} style={{ display: "none" }}>
                    <SelectCustom
                      id={"location_select"}
                      options={locations}
                      selectedOption={locationSelectedOption}
                      onChange={onLocationSelect}
                    />
                  </div>
                  <div id={"locationShow"} style={{ display: "block" }}>
                    <h3 className="location-show">
                      {locationSelectedOption.label}
                    </h3>
                  </div>
                </div>
              )}
              <div className="col-auto">
                <button
                  className="event-btn-change-city"
                  id="eventChangeLocation"
                  onClick={showSelect}
                >
                  Change city
                </button>
              </div>
            </div>
          </div>
        </div>
        <EventsTab locationId={location} />
      </div>
    </div>
  );
}

export default UpcomingEvents;
