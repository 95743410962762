import React from "react";
import Banner from "../common/Banner";
//import NativeAd from "./NativeAd";
import RoomsForRent from "../property/FeaturedProperty";
import Divider from "../common/Divider";
import RecentJobs from "../job/FeaturedJobs";
import { useEffect } from "react";
import { initializeTagManager } from "../../TagManager";
import Loader from "../common/Loader";
import { Helmet } from "react-helmet-async";
import PopularCategories from "../common/PopularCategories";
import AdvertiseWithUs from "../template/AdvertiseWithUs";
import HomeTopFeatured from "../common/TopFeatured";

function BodyContent(props) {
  function toggleMobilenav() {
    var body = document.body;
    var hasClass = body.classList.contains("nav2-is-toggled");
    if (hasClass) {
      document.body.classList.remove("nav2-is-toggled");
      document.body.classList.remove("body-no-scroll");
      document.getElementById("ham").textContent = "menu";
    }
  }
  useEffect(() => {
    initializeTagManager();
    toggleMobilenav();
  }, []);
  return (
    <>
      <Helmet>
        <title>{`Sahi Search - ${props.pageTitle}`}</title>
        <meta name="title" content={props.metaTitle} />
        <meta name="description" content={props.metaContent} />
      </Helmet>
      <div id="globalLoader" style={{ display: "display" }}>
        <Loader />
      </div>
      <Banner />
      <HomeTopFeatured />
      {/* <NativeAd/> */}
      <Divider />
      <RoomsForRent />
      <Divider />
      <RecentJobs />
      <Divider />
      {/* <NativeAd/> */}
      <PopularCategories />
      <Divider />
      <AdvertiseWithUs />
    </>
  );
}
export default BodyContent;
