import React from "react";
import { GeoAlt, Calendar, TicketPerforated } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

function EventCard(props) {
  const dateFormat = (d) => {
    let date = new Date(d);
    const dayOfWeek = date.toLocaleString("en-US", { weekday: "short" });
    var month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();
    const day = date.getDate();

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedDate = `${dayOfWeek}, ${day} ${month} ${year}, ${hours}:${minutes < 10 ? "0" + minutes : minutes} ${ampm}`;

    return formattedDate;
  };
  function goToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <>
      <div className="card">
        {props.image && (
          <Link onClick={goToTop} to={props.detail_url ? props.detail_url : "/"}>
            {/* <div
              style={{
                height: props.imageHeight ? props.imageHeight : "212px",
                width: "100%",
                paddingBottom: "75%",
                //backgroundColor: "lightgray",
                //backgroundRepeat: "no-repeat",
                //backgroundSize: "cover",
                //backgroundPosition: "center",
                background: `url(${props.image}) lightgray 50% / contain no-repeat`,
              }}
              className="text-center"
            >
              <img
                src={props.image}
                alt={props.title}
                style={{
                  height: props.imageHeight ? props.imageHeight : "212px",
                }}
              />
            </div> */}
            <div
              style={{
                position: "relative", // Required for child elements positioning
                width: "100%", // Adjust the width as needed
                height: props.imageHeight ? props.imageHeight : "370px",
                zIndex: "1",
                textAlign: "center",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "lightgray 50%",
                  backgroundImage: `url(${props.image})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  filter: "blur(50px)", // Adjust the blur value as needed
                  zIndex: "-1",
                }}
              />
              <img
                src={props.image}
                alt={props.title}
                style={{
                  width: "auto",
                  height: props.imageHeight ? props.imageHeight : "370px",
                }}
              />
            </div>
          </Link>
        )}
        <div className={props.home ? "remove-body-padding-bottom card-body" : "card-body"}>
          <h6
            className="card-title mt-2"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            <Link onClick={goToTop} to={props.detail_url ? props.detail_url : "/"} className={"text-decoration-none"}>
              {props.title ? props.title : ""}
            </Link>
          </h6>
          <p className="card-subtitle">
            <GeoAlt color="#4E5BA6" />
            &nbsp;
            {props.address1 ? `${props.address1}, ` : ""}
            {props.location ? props.location : ""}
            {props.postalcode ? ` - ${props.postalcode}` : ""}
          </p>
          {parseInt(props.showStartDate) === parseInt(1) && (
            <p className="card-subtitle">
              &nbsp;
              <Calendar size={10} color="#4E5BA6" />
              &nbsp;{dateFormat(props.startDate)} {/* - {dateFormat(props.endDate)} */}
            </p>
          )}
          <p>
            <span className="badge rounded-pill card-business">{props.type ? props.type : ""}</span>
            {props.adultOnly && <span className="badge rounded-pill for-adults">18+ only</span>}
            {new Date(props.startTicketDate).getTime() >= new Date().getTime() && <span className="badge rounded-pill for-adults">Coming soon</span>}
            {new Date(props.endDate).getTime() <= new Date().getTime() && <span className="badge rounded-pill for-adults">Expired</span>}
          </p>
        </div>
        {/* footer for home scren only */}
        {new Date(props.startTicketDate).getTime() <= new Date().getTime() && new Date(props.endDate).getTime() >= new Date().getTime() && props.home && (
          <div className="card-footer bg-transparent border-1">
            <p className="card-footer-content" style={{ margin: "0px" }}>
              <Link onClick={goToTop} to={props.detail_url ? props.detail_url : "/"} className="btn btn-sm btn-md  text-center card-btn-buy-ticket">
                <TicketPerforated size={25} color="#ffffff" />
                &nbsp;&nbsp;Buy your tickets online
              </Link>
            </p>
          </div>
        )}

        {new Date(props.startTicketDate).getTime() <= new Date().getTime() && new Date(props.endDate).getTime() >= new Date().getTime() && !props.home && props.price && (
          <div className="card-footer bg-transparent border-1">
            <p className="card-footer-content" style={{ margin: "0px" }}>
              <Link onClick={goToTop} to={props.detail_url ? props.detail_url : "/"} className="btn btn-sm btn-md  text-center card-btn-view-detail">
                From £{props.price ? props.price : ""}
              </Link>
            </p>
          </div>
        )}
      </div>
    </>
  );
}
export default EventCard;
