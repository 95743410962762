import React, { useState } from "react";
import SelectCustom from "../common/SelectCustom";
import { Helmet } from "react-helmet-async";
import { useHistory, useLocation } from "react-router-dom";

export default function PropertySearchModel(props) {
  const [ctax, setCtax] = useState(props.ctax);
  const [sharing, setSharing] = useState(props.sharing);
  const [deposit, setDeposit] = useState(props.deposit);
  const [bills, setBills] = useState(props.bills);
  const history = useHistory();
  //using location hook to get params from URL
  const location = useLocation();

  let queryParams = new URLSearchParams(location.search);
  let queryCategory = queryParams.get("category");
  let queryLocation = queryParams.get("location");
  let queryPage = queryParams.get("page");
  let queryLimit = queryParams.get("limit");
  let queryType = props.type;
  let queryGender = props.gender;
  let querySort = props.sort;
  function handleDropDown(event, type) {
    if (type === "property") {
      queryType = event.target.value;
    } else if (type === "gender") {
      queryGender = event.target.value;
    } else if (type === "sort") {
      querySort = event.target.value;
    }
  }
  function handleCheck(event, paramType) {
    if (event.target.checked) {
      event.target.checked = true;
      if (paramType === "ctax") {
        setCtax(1);
      } else if (paramType === "sharing") {
        setSharing(1);
      } else if (paramType === "deposit") {
        setDeposit(1);
      } else if (paramType === "bills") {
        setBills(1);
      }
    } else {
      event.target.checked = false;
      if (paramType === "ctax") {
        setCtax(0);
      } else if (paramType === "sharing") {
        setSharing(0);
      } else if (paramType === "deposit") {
        setDeposit(0);
      } else if (paramType === "bills") {
        setBills(0);
      }
    }
  }
  const handleSubmit = () => {
    queryLocation = document.getElementById("location_select").title;

    history.push(
      `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}&type=${queryType}&gender=${queryGender}&ctax=${ctax}&sharing=${sharing}&deposit=${deposit}&bills=${bills}`
    );
  };
  return (
    <>
      <Helmet>
        <title>{`Sahi Search - ${props.pageTitle}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Refine Search
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12 col-md-12 mb-2">
                  <label className="form-label modal-filter-label">
                    Location (Town)
                  </label>
                  <SelectCustom
                    className={"modal-filter"}
                    id={"location_select"}
                    options={props.locations ? props.locations : false}
                    type="location"
                    selectedOption={props.locationSelectedOption}
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <label className="form-label modal-filter-label">
                    Type of Property
                  </label>
                  <select
                    defaultValue={props.type}
                    onChange={(event) => handleDropDown(event, "property")}
                    className="form-select modal-filter"
                    aria-label="Default select example"
                  >
                    <option value="0">Choose your property type</option>
                    <option value="1">Single Room</option>
                    <option value="2">Double Room</option>
                    <option value="3">Box Room</option>
                    <option value="4">Studio Flat</option>
                    <option value="5">Flat</option>
                    <option value="6">House</option>
                  </select>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <label className="form-label modal-filter-label">
                    Gender preference
                  </label>
                  <select
                    defaultValue={props.gender}
                    onChange={(event) => handleDropDown(event, "gender")}
                    name="genderSelection"
                    className="form-select modal-filter"
                  >
                    <option value={3}>Any</option>
                    <option value={1}>Male</option>
                    <option value={2}>Female</option>
                  </select>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <label className="form-label modal-filter-label">
                    Sort by
                  </label>
                  <select
                    defaultValue={props.sort}
                    onChange={(event) => handleDropDown(event, "sort")}
                    className="form-select modal-filter"
                  >
                    <option value="null">Relevance</option>
                    <option value="name">A - Z</option>
                  </select>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <hr className="section-divider" />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="row radio-filter">
                    <div className="col-lg-9 col-md-9 col-9">
                      <label className="form-check-label modal-filter-label">
                        Council tax included
                      </label>
                    </div>
                    <div className="col-lg-3 col-md-3 col-3">
                      <div className="form-check form-switch">
                        <input
                          checked={
                            parseInt(ctax) === parseInt(1) ? true : false
                          }
                          onChange={(event) => handleCheck(event, "ctax")}
                          className="form-check-input"
                          type="checkbox"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row radio-filter">
                    <div className="col-lg-9 col-md-9 col-9">
                      <label className="form-check-label modal-filter-label">
                        Sharing allowed
                      </label>
                    </div>
                    <div className="col-lg-3 col-md-3 col-3">
                      <div className="form-check form-switch">
                        <input
                          checked={
                            parseInt(sharing) === parseInt(1) ? true : false
                          }
                          onChange={(event) => handleCheck(event, "sharing")}
                          name="sharingAllowed"
                          className="form-check-input"
                          type="checkbox"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row radio-filter">
                    <div className="col-lg-9 col-md-9 col-9">
                      <label className="form-check-label modal-filter-label">
                        Deposit required
                      </label>
                    </div>
                    <div className="col-lg-3 col-md-3 col-3">
                      <div className="form-check form-switch">
                        <input
                          checked={
                            parseInt(deposit) === parseInt(1) ? true : false
                          }
                          onChange={(event) => handleCheck(event, "deposit")}
                          name="depositRequire"
                          className="form-check-input"
                          type="checkbox"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row radio-filter">
                    <div className="col-lg-9 col-md-9 col-9">
                      <label className="form-check-label modal-filter-label">
                        Bills included
                      </label>
                    </div>
                    <div className="col-lg-3 col-md-3 col-3">
                      <div className="form-check form-switch">
                        <input
                          checked={
                            parseInt(bills) === parseInt(1) ? true : false
                          }
                          onChange={(event) => handleCheck(event, "bills")}
                          name="billsInculded"
                          className="form-check-input"
                          type="checkbox"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer align-content-center justify-content-center">
              <button
                type="button"
                className="btn-sm btn-advertise-light hidden-xxs"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-sm btn-advertise-danger"
                data-bs-dismiss="modal"
                onClick={handleSubmit}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
