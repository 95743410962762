import React from "react";
import {ArrowRightShort} from "react-bootstrap-icons";
import {Link} from "react-router-dom";

function ShowMore(props){
    function goToTop(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    return(
        <>
            <div className='row text-center'>
                <Link
                    onClick={goToTop}
                    to={props.url?props.url:'/'}
                    className='show-more text-decoration-none'>
                    Show more <ArrowRightShort size='25' style={{color:'#475467'}}/>
                </Link>
            </div>
        </>
    );
}export default ShowMore