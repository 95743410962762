import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

export default function EventFilters(props) {
  const [categories, setCategories] = useState();
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    const options = {
      method: "GET",
      url: process.env.REACT_APP_BASE_URL + "/api/categories",
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
      },
      params: {
        parent: 4,
        page: 1,
        limit: 50,
      },
    };

    await axios
      .request(options)
      .then(function (response) {
        setCategories(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  function handleSelection(event, label) {
    var selectedValue = event.target.value;
    props.handle(selectedValue, label);
  }
  function handleCheck(event, paramType) {
    if (event.target.checked) {
      event.target.checked = true;
      props.handle(1, paramType);
    } else {
      event.target.checked = false;
      props.handle(0, paramType);
    }
  }

  return (
    <div className="property-search hidden-xs hidden-sm">
      <Helmet>
        <title>{`Sahi Search - ${props.pageTitle}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
          <label className="form-label">Type of Event</label>
          <select
            defaultValue={props.category}
            onChange={(event) => handleSelection(event, "category")}
            name="eventSelection"
            className="form-select"
            aria-label="Default select example"
          >
            <option value="4">All</option>
            {categories
              ? categories.map((element, index) => {
                  return (
                    <option key={index} value={element.id}>
                      {element.name}
                    </option>
                  );
                })
              : false}
          </select>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
          <label className="form-label">Time</label>
          <select
            defaultValue={props.time}
            onChange={(event) => handleSelection(event, "time")}
            name="timeSelection"
            className="form-select"
          >
            <option value={0}>Any</option>
          </select>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <hr className="section-divider" />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row radio-filter">
            <div className="col-lg-9 col-md-9 col-9">
              <label className="form-check-label">
                Show only child-safe events
              </label>
            </div>
            <div className="col-lg-3 col-md-3 col-3">
              <div className="form-check form-switch">
                <input
                  checked={parseInt(props.safe) === parseInt(1) ? true : false}
                  onChange={(event) => handleCheck(event, "safe")}
                  name="safeEvent"
                  className="form-check-input"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
          <div className="row radio-filter">
            <div className="col-lg-9 col-md-9 col-9">
              <label className="form-check-label">Show only free events</label>
            </div>
            <div className="col-lg-3 col-md-3 col-3">
              <div className="form-check form-switch">
                <input
                  checked={parseInt(props.free) === parseInt(1) ? true : false}
                  onChange={(event) => handleCheck(event, "free")}
                  name="freeEvent"
                  className="form-check-input"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
