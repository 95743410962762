import React from "react";
import LogoMark from "../../gallery/default/logomark.png";
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setUser, setSignup, setGuest } from "../../app/features/user/userSlice";
import { BeatLoader } from "react-spinners";

function EventLoginForm(props) {
  const cart = useSelector((state) => state.event.cart);
  const currentEvent = useSelector((state) => state.event.currentEvent);

  const dispacth = useDispatch();

  const [formError, setFormError] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const handleEmail = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    if (!isValidEmail(event.target.value)) {
      setFormError("Please enter a valid email!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);
    }
  };
  const handlePassword = (event) => {
    const password = event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: password,
    });
    const inputEmail = document.getElementsByName("email");
    const firstInputElement = inputEmail[0];
    if (password.length < 6) {
      setFormError("Password must be atleast 6 character!");
      setTimeout(() => {
        setFormError(null);
      }, 5000);
    } else if (!isValidEmail(formData.email)) {
      setFormError("Please enter a valid email!");
      firstInputElement.focus();
      setTimeout(() => {
        setFormError(null);
      }, 5000);
      return;
    }
  };

  const login = async (event) => {
    event.preventDefault();
    const loginButton = event.target; // document.getElementById("logindefaultbtn");
    loginButton.classList.remove("btn-login");
    loginButton.classList.add("btn-disable-login");
    const loader = document.getElementById("loader");
    loader.style.display = "block";

    if (formData.email === "") {
      setFormError("Please enter a valid email!");
      setTimeout(() => {
        setFormError(null);
        loader.style.display = "none";
      }, 5000);
      return;
    } else if (formData.password === "") {
      setFormError("Password must be atleast 6 character!");
      setTimeout(() => {
        setFormError(null);
        loader.style.display = "none";
      }, 5000);
      return;
    } else {
      await axios
        .request({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/api/sanctum/csrf-cookie`,
        })
        .then(async (sanRep) => {
          await axios
            .request({
              method: "POST",
              url: `${process.env.REACT_APP_BASE_URL}/api/login`,
              data: formData,
            })
            .then(function (response) {
              dispacth(setUser(response.data.data));
              sessionStorage.setItem("user", JSON.stringify(response.data.data));
              localStorage.setItem("user", JSON.stringify(response.data.data));
            })
            .catch(function (error) {
              setFormError(error.response.data.message);
              setTimeout(() => {
                setFormError(null);
                loader.style.display = "none";
              }, 5000);
              console.error(error);
            });
        });
    }
  };
  const goToSignup = (event) => {
    event.preventDefault();
    dispacth(setSignup());
  };
  const goToGuest = (event) => {
    event.preventDefault();
    dispacth(setGuest());
  };

  return (
    <div className="login-container">
      <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="col-12 d-flex justify-content-center mb-3">
            <img src={LogoMark} alt="Sahi Search" />
          </div>
          <p className="remaining-time">Time remaining : {props.timeRemaining}</p>
          <div className="col-12">
            <button className="btn btn-blue" onClick={(event) => goToGuest(event)}>
              Checkout as Guest
            </button>
          </div>
          <p className="text-center mt-3">or</p>
          <h3 className="login-heading">Log in to continue</h3>

          <form className="row g-3" autoComplete="off">
            {formError && (
              <div className="alert alert-danger" role="alert">
                {formError}
              </div>
            )}
            <div className="col-12">
              <label htmlFor="staticEmail2" className="form-label input-label">
                Email
              </label>
              <input type="email" className="form-control input-text-size" defaultValue={formData.email} name="email" onBlur={handleEmail} />
            </div>
            <div className="col-12">
              <label htmlFor="inputPassword2" className="form-label input-label">
                Password
              </label>
              <input type="password" className="form-control input-text-size" name="password" defaultValue={formData.password} onBlur={handlePassword} />
            </div>
            {/* <div className="col-12">
              <a href="/" className="forgot-password mb-3">
                Forgot passowrd
              </a>
            </div> */}
            <div className="col-12 text-center mt-4">
              <button id="logindefaultbtn" type="button" className={!formError ? "btn btn-login mb-3" : "btn btn-disable-login mb-3"} onClick={login}>
                Sign in
              </button>
              <div id="loader" style={{ display: "none" }}>
                <BeatLoader color="#C6153B" />
              </div>
            </div>
            <div className="col-12">
              <p className="account">
                Don't have an account?{" "}
                <span>
                  <a href="/" className="sign-up" onClick={(event) => goToSignup(event)}>
                    Sign up
                  </a>
                </span>
              </p>
            </div>
          </form>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="event-align-middle">
            <h3 className="login-event-title">{currentEvent.name}</h3>
            <p className="ticket-waiting">
              {cart.productsCount}&nbsp;
              {cart.productsCount === 1 ? "ticket" : "tickets"}
            </p>
            <p className="inclusive-fee">All ticket prices are inclusive of booking fees</p>
            <p className="not-reserved">Tickets are not reserved until you have completed payment.</p>
            <h3 className="grand-total">£{cart.grandTotal.toFixed(2)}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventLoginForm;
