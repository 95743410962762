import React from "react";
import EventCard from "./EventCard";
import ShowMore from "../common/ShowMore";
import DefaultBL from "../../gallery/default/defaultBusinessLogo.png";

function FeaturedEvents({ events }) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
          <h3 className="featured-business-title">Featured events</h3>
        </div>
        {events.map((element, index) => {
          return (
            <div
              className="col-lg-4 col-md-6 col-sm-6 col-xs-12 card-holder"
              key={index}
            >
              <EventCard
                imageHeight="370px"
                image={element.image_name ? element.image_path : DefaultBL}
                title={element.name ? element.name : ""}
                address1={element.address1 ? element.address1 : ""}
                location={element.location ? element.location : ""}
                postalcode={element.postal_code ? element.postal_code : ""}
                page={element.page ? element.page : ""}
                type={
                  element.category
                    ? element.category.charAt(0).toUpperCase() +
                      element.category.slice(1)
                    : ""
                }
                startDate={element.start_date}
                startTicketDate={element.ticket_start_at}
                endDate={element.end_date}
                showStartDate={element.show_start_date}
                adultOnly={element.adult_event}
                detail_url={element.page + "/" + element.slug}
                views={element.views}
                price={
                  element.tickets.length > 0 ? element.tickets[0].price : null
                }
              />
            </div>
          );
        })}
        <ShowMore url={"/search?category=4&location=1&page=1&limit=10"} />
      </div>
    </div>
  );
}

export default FeaturedEvents;
