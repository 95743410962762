import React, { useState } from "react";
import SelectCustom from "../common/SelectCustom";
import { Helmet } from "react-helmet-async";
import { useHistory, useLocation } from "react-router-dom";

export default function JobSerachModel(props) {
  const [disclosed, setDisclosed] = useState(props.disclosed);
  const history = useHistory();
  //using location hook to get params from URL
  const location = useLocation();

  let queryParams = new URLSearchParams(location.search);
  let queryCategory = queryParams.get("category");
  let queryLocation = queryParams.get("location");
  let queryPage = queryParams.get("page");
  let queryLimit = queryParams.get("limit");
  let queryTitle = props.title;
  let queryJob = props.job;
  let queryLevel = props.level;
  let querySort = props.sort;

  function handleDropDown(event, type) {
    if (type === "sort") {
      querySort = event.target.value;
    } else if (type === "job") {
      queryJob = event.target.value;
    } else if (type === "level") {
      queryLevel = event.target.value;
    }
  }
  function handleInput(event) {
    queryTitle = event.target.value;
  }
  function handleCheck(event) {
    if (event.target.checked) {
      event.target.checked = true;
      setDisclosed(1);
    } else {
      event.target.checked = false;
      setDisclosed(0);
    }
  }
  const handleSubmit = () => {
    queryLocation = document.getElementById("location_select").title;

    history.push(
      `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}&title=${queryTitle}&job=${queryJob}&level=${queryLevel}&disclosed=${disclosed}`
    );
  };

  return (
    <>
      <Helmet>
        <title>{`Sahi Search - ${props.pageTitle}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Refine Search
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12 col-md-12 mb-2">
                  <label className="form-label modal-filter-label">
                    Location (Town)
                  </label>
                  <SelectCustom
                    className={"modal-filter"}
                    id={"location_select"}
                    options={props.locations ? props.locations : false}
                    type="location"
                    selectedOption={props.locationSelectedOption}
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <label className="form-label modal-filter-label">
                    Job Title
                  </label>
                  <input
                    id="search-title"
                    type="text"
                    className="form-control modal-filter"
                    placeholder="Enter a job title"
                    aria-describedby="button-addon1"
                    defaultValue={props.title ? props.title : ""}
                    onChange={handleInput}
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <label className="form-label">Type of Job</label>
                  <select
                    defaultValue={props.job}
                    onChange={(event) => handleDropDown(event, "job")}
                    className="form-select"
                  >
                    <option value="0">All</option>
                    <option value="1">Full Time</option>
                    <option value="2">Part Time</option>
                  </select>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <label className="form-label">Level</label>
                  <select
                    defaultValue={props.level}
                    onChange={(event) => handleDropDown(event, "level")}
                    className="form-select"
                  >
                    <option value="0">All</option>
                    <option value="1">Beginner</option>
                    <option value="2">Intermediate</option>
                    <option value="3">Expert</option>
                  </select>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <label className="form-label">Sort by</label>
                  <select
                    defaultValue={props.sort}
                    onChange={(event) => handleDropDown(event, "sort")}
                    className="form-select"
                  >
                    <option value="null">Relevance</option>
                    <option value="name">A - Z</option>
                  </select>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <hr className="section-divider" />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="row radio-filter">
                    <div className="col-lg-9 col-md-9 col-9">
                      <label className="form-check-label modal-filter-label">
                        Hide jobs with undisclosed salary
                      </label>
                    </div>
                    <div className="col-lg-3 col-md-3 col-3">
                      <div className="form-check form-switch">
                        <input
                          checked={
                            parseInt(disclosed) === parseInt(1) ? true : false
                          }
                          onChange={(event) => handleCheck(event, "disclosed")}
                          className="form-check-input"
                          type="checkbox"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <hr className="section-divider" />
                </div>
              </div>
            </div>
            <div className="modal-footer align-content-center justify-content-center">
              <button
                type="button"
                className="btn btn-advertise-light hidden-xxs"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-advertise-danger"
                data-bs-dismiss="modal"
                onClick={handleSubmit}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
