import React from "react";
import EventCard from "./EventCard";
import ShowMore from "../common/ShowMore";
import { useState, useEffect } from "react";
import axios from "axios";
import DefaultBL from "../../gallery/default/defaultBusinessLogo.png";

function TabEvents(props) {
  const [data, setData] = useState();
  let categoryId = "4";
  if (props.categoryId) {
    categoryId = props.categoryId;
  }
  let report = null;
  if (props.report) {
    report = props.report;
  }
  useEffect(() => {
    async function fetchData() {
      const options = {
        method: "GET",
        url: process.env.REACT_APP_BASE_URL + "/api/search",
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
        params: {
          report: report,
          category: categoryId,
          location: props.locationId,
          page: 1,
          limit: 3,
        },
      };

      await axios
        .request(options)
        .then(function (response) {
          setData(response.data.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    }
    fetchData();
  }, [props.locationId]);
  return (
    <div className="row">
      {data
        ? data.map((element, index) => {
            return (
              <div
                className="col-lg-4 col-md-6 col-sm-6 col-xs-12 card-holder"
                key={index}
              >
                <EventCard
                  imageHeight="370px"
                  image={element.image_name ? element.image_path : DefaultBL}
                  title={element.name ? element.name : ""}
                  address1={element.address1 ? element.address1 : ""}
                  location={element.location ? element.location : ""}
                  postalcode={element.postal_code ? element.postal_code : ""}
                  page={element.page ? element.page : ""}
                  type={
                    element.category
                      ? element.category.charAt(0).toUpperCase() +
                        element.category.slice(1)
                      : ""
                  }
                  startTicketDate={element.ticket_start_at}
                  startDate={element.start_date}
                  endDate={element.end_date}
                  showStartDate={element.show_start_date}
                  adultOnly={element.adult_event}
                  detail_url={element.page + "/" + element.slug}
                  price={
                    element.tickets.length > 0 ? element.tickets[0].price : null
                  }
                  views={element.views}
                />
              </div>
            );
          })
        : false}
      <ShowMore
        url={`/search?category=4&location=${props.locationId}&page=1&limit=10`}
      />
    </div>
  );
}

export default TabEvents;
