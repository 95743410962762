import React, { useEffect, useState } from "react";
import DefaultDetailPageLogo from "../../gallery/default/defaultDetailPageLogo.png";
//import adImage from '../../gallery/rectangle .png'
import Divider from "./Divider";
//import NativeAdImage from "../native-ad.png";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Similar from "./Similar";
import LeftCarouselArrow from "../../gallery/icons/leftCarouselArrow.png";
import RightCarouselArrow from "../../gallery/icons/rightCarouselArrow.png";
/* import GeoAlt from '../../gallery/icons/geoAlt.png'
import Dot from '../../gallery/icons/dot.png'
import Eye from '../../gallery/icons/eye.png' */
import { GeoAlt, Eye, Dot } from "react-bootstrap-icons";
import Phone from "../../gallery/icons/phone.png";
import Mail from "../../gallery/icons/mail.png";
import Web from "../../gallery/icons/web.png";
import FB from "../../gallery/icons/fb.png";
import Instagram from "../../gallery/icons/instagram.png";
import Twitter from "../../gallery/icons/twitter.png";
import YouTube from "../../gallery/icons/youtube.png";
import Linkedin from "../../gallery/icons/linkedin.png";
import Tripadvisor from "../../gallery/icons/tripadvisor.png";
import Degree45 from "../../gallery/icons/45degree.png";
import ArrowLeft from "../../gallery/icons/arrowLeft.png";
import ArrowRight from "../../gallery/icons/arrowRight.png";
import { Link } from "react-router-dom";
import { initializeTagManager } from "../../TagManager";
import { Helmet } from "react-helmet-async";
import PopularCategories from "./PopularCategories";
import AdvertiseWithUs from "../template/AdvertiseWithUs";
import { BeatLoader } from "react-spinners";

function DetailWindow(props) {
  const location = useLocation();
  const substrings = location.pathname.split("/");
  const type = substrings["1"];
  const slug = substrings["2"];
  var detailLogo = null;
  var pillData = null;
  let pageTitle = "Sahi Search";
  const reportUrl = window.location.href;

  const [data, setData] = useState();

  useEffect(() => {
    setData(null);
    initializeTagManager();
    fetchData();
  }, [type, slug]);
  async function fetchData() {
    await axios
      .request({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/api/${type}/${slug}`,
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
      })
      .then(function (response) {
        setData(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  const detailInfo = async (event, category, item) => {
    event.preventDefault();
    event.target.style.display = "none";
    /*  var detailButton = document.getElementById("detail_info_button");
        detailButton.setAttribute("disabled", true) */
    var detailDiv = document.getElementById("detail_info");
    if (detailDiv.style.display === "none") {
      detailDiv.style.display = "block";
      /* detailButton.style.opacity= "50%"; */
    }
    await axios
      .request({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/api/views`,
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
        params: {
          category: category,
          item: item,
        },
      })
      .then(function (response) {})
      .catch(function (error) {
        console.error(error);
      });
  };
  if (data) {
    if (data.page === "business") {
      if (data.image_name) {
        detailLogo = data.image_path;
      } else {
        detailLogo = DefaultDetailPageLogo;
      }
    } else if (data.page === "property" || data.page === "job") {
      detailLogo = null; //DefaultDetailPageLogo;
    }

    if (data.page === "business") {
      pillData = data.category;
    } else if (data.page === "job") {
      pillData = data.type;
    } else if (data.page === "property") {
      pillData = data.property_type;
    } else {
      pillData = data.category;
    }
    pageTitle = pageTitle + " - " + data.name;
  }
  const handleCopy = (event) => {
    event.preventDefault();
    const currentUrl = window.location.href;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(currentUrl)
        .then(() => {
          console.log("URL copied to clipboard!");
        })
        .catch((error) => {
          console.error("Failed to copy URL: ", error);
        });
    } else {
      fallbackCopyURL(currentUrl);
    }
    var myButton = document.getElementById("copylinkbtn");
    myButton.className = "btn-copy-pink btn-detail-light";
    myButton.innerHTML = `<img src=${Degree45}  alt=""/> Copied`;
    myButton.setAttribute("disabled", true);

    setTimeout(function () {
      myButton.className = "btn-copy-pink";
      myButton.innerHTML = `<img src=${Degree45}  alt=""/> Copy link to this page`;
      myButton.removeAttribute("disabled");
    }, 5000);
  };
  const fallbackCopyURL = (url) => {
    const textArea = document.createElement("textarea");
    textArea.value = url;
    textArea.style.position = "fixed"; // Make it invisible
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      console.log("URL copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy URL: ", error);
    }
    document.body.removeChild(textArea);
  };
  const dateFormat = (d) => {
    let date = new Date(d);

    var day = date.getDate();
    var month = date.toLocaleString("en-US", { month: "long" });
    var year = date.getFullYear();

    var formattedDate = day + " " + month + " " + year;
    return formattedDate;
  };
  const handleGoTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content="" />
      </Helmet>
      {!data && (
        <div className="loader-in-component-body">
          <BeatLoader color="#C6153B" onLoad={handleGoTop} />
        </div>
      )}
      {data && (
        <>
          <div className="container">
            <div className="inner-content">
              <div className="row bread-and-sort">
                <div className="col-lg-8 col-md-8 col-sm-12">
                  {data && data.gallery.length > 0 && (
                    <div
                      id="carouselExampleCaptions"
                      className="carousel slide"
                    >
                      <div className="carousel-inner carousel-inner-height align-content-center">
                        {data.gallery.map((element, index) => {
                          var customActive = "";
                          if (index === 0) {
                            customActive = "active";
                          }
                          return (
                            <div
                              style={{
                                position: "relative", // Required for child elements positioning
                                width: "100%", // Adjust the width as needed
                                height: "100%",
                                zIndex: "1",
                                textAlign: "center",
                                overflow: "hidden",
                              }}
                              className={`carousel-item ${customActive}`}
                              key={`carousel_${index}`}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  backgroundImage: `url(${element.image_path}/${element.image_name})`,
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  filter: "blur(50px)", // Adjust the blur value as needed
                                  zIndex: "-1",
                                }}
                              />
                              <img
                                className="d-block carousel-img-height img-fluid"
                                alt="..."
                                src={`${element.image_path}/${element.image_name}`}
                              />
                            </div>
                          );
                        })}
                      </div>
                      {data.gallery.length > 1 && (
                        <div className="carousel-nav hidden-xs">
                          <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide="prev"
                          >
                            <span aria-hidden="true">
                              <img src={LeftCarouselArrow} alt="" />
                            </span>
                            <span className="visually-hidden">Previous</span>
                          </button>
                          <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide="next"
                          >
                            <span aria-hidden="true">
                              <img src={RightCarouselArrow} alt="" />
                            </span>
                            <span className="visually-hidden">Next</span>
                          </button>
                        </div>
                      )}
                      {data.gallery.length > 1 && (
                        <div className="carousel-nav hidden-md">
                          <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide="prev"
                          >
                            <span aria-hidden="true">
                              <img src={ArrowLeft} alt="" />
                            </span>
                            <span className="visually-hidden">Previous</span>
                          </button>
                          <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide="next"
                          >
                            <span aria-hidden="true">
                              <img src={ArrowRight} alt="" />
                            </span>
                            <span className="visually-hidden">Next</span>
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {/* <div className='text-center'>
                            <div className='container'>
                                <img className='detal-ad' src={NativeAdImage} alt='Native ad will go here' style={{width:'inherit'}}/>
                            </div>
                        </div> */}
                  <div className="hidden-xs">
                    <h6 className="detail-heading">
                      {data ? (data.name ? data.name : "") : ""}
                    </h6>
                    <p
                      className="detail-desc"
                      dangerouslySetInnerHTML={{
                        __html: data
                          ? data.detail
                            ? data.detail.replace(/\n/g, "<br />")
                            : ""
                          : "",
                      }}
                    />
                    <div className="row">
                      {data && data.property_type && (
                        <div className="col-6">
                          <strong>Property Type: </strong>
                          {data
                            ? data.property_type
                              ? data.property_type
                              : ""
                            : ""}
                        </div>
                      )}
                      {data && data.type && (
                        <div className="col-6">
                          <strong>Job Type: </strong>
                          {data ? (data.type ? data.type : "") : ""}
                        </div>
                      )}
                      {data && data.level && (
                        <div className="col-6">
                          <strong>Job Level: </strong>
                          {data ? (data.level ? data.level : "") : ""}
                        </div>
                      )}
                      {data && data.salary_type && (
                        <div className="col-6">
                          <strong>Salary Type: </strong>
                          {data
                            ? data.salary_type
                              ? data.salary_type
                              : ""
                            : ""}
                          {data &&
                            data.salary_value &&
                            `(£${data.salary_value})`}
                        </div>
                      )}
                      {data && data.sharing && (
                        <div className="col-6">
                          <strong>Sharing: </strong>
                          {data ? (data.sharing ? data.sharing : "") : ""}
                        </div>
                      )}
                      {data && data.pets && (
                        <div className="col-6">
                          <strong>Pets: </strong>
                          {data ? (data.pets ? data.pets : "") : ""}
                        </div>
                      )}
                      {data && data.gender_preference && (
                        <div className="col-6">
                          <strong>Gender Preference: </strong>
                          {data
                            ? data.gender_preference
                              ? data.gender_preference
                              : ""
                            : ""}
                        </div>
                      )}
                      {data && data.deposit_required && (
                        <div className="col-6">
                          <strong>Deposit Required: </strong>
                          {data
                            ? data.deposit_required
                              ? data.deposit_required
                              : ""
                            : ""}
                          {data &&
                            data.deposit_value &&
                            `(£${data.deposit_value})`}
                        </div>
                      )}
                      {data && data.amount && (
                        <div className="col-6">
                          <strong>Monthly Rent / Price: </strong>
                          {data ? (data.amount ? `£${data.amount}` : "") : ""}
                        </div>
                      )}
                      {data && data.bills_included && (
                        <div className="col-6">
                          <strong>Bills Included: </strong>
                          {data
                            ? data.bills_included
                              ? data.bills_included
                              : ""
                            : ""}
                        </div>
                      )}
                      {data && data.tax_included && (
                        <div className="col-6">
                          <strong>Council Tax Included: </strong>
                          {data
                            ? data.tax_included
                              ? data.tax_included
                              : ""
                            : ""}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  {detailLogo && (
                    <img className="detail-logo" src={detailLogo} alt="..." />
                  )}
                  <h3 className="detail-business-title">
                    {data ? (data.name ? data.name : "") : ""}
                  </h3>
                  <p className="location-on-detail paragraph-margin-sm">
                    {/* <img src={GeoAlt} alt={''}/> */}
                    <GeoAlt />
                    &nbsp;
                    {data
                      ? data.address1
                        ? data.address2
                          ? `${data.address1} , ${data.address2}`
                          : data.address1
                        : ""
                      : ""}
                    {data ? data.address1 ? <br /> : "" : ""}
                    &nbsp;{data
                      ? data.location
                        ? data.location
                        : ""
                      : ""}{" "}
                    {data
                      ? data.postal_code
                        ? ` - ${data.postal_code}`
                        : ""
                      : ""}
                  </p>
                  <p className="paragraph-margin-sm">
                    {data.page === "business" && (
                      <Link
                        to={`/search?category=${data.category_id}&location=1&page=1&limit=10`}
                      >
                        <span className="badge rounded-pill card-business">
                          {/* <img src={Dot} alt={''}/> */}
                          <Dot size={30} />
                          &nbsp;&nbsp;{data ? pillData : ""}
                        </span>
                      </Link>
                    )}
                    {data.page !== "business" && (
                      <span className="badge rounded-pill card-business">
                        {/* <img src={Dot} alt={''}/> */}
                        <Dot size={30} />
                        &nbsp;&nbsp;{data ? pillData : ""}
                      </span>
                    )}

                    {data && (data.salary_value || data.amount) && (
                      <span className="badge rounded-pill salary-pill">
                        &nbsp;
                        {`£${
                          data.salary_value
                            ? data.salary_value
                            : data.amount
                            ? data.amount
                            : ""
                        }`}
                      </span>
                    )}
                  </p>
                  <p className="detail-views paragraph-margin-sm">
                    {/* <img src={Eye} alt={''}/> */}
                    <Eye size={15} />
                    &nbsp;&nbsp;{data
                      ? data.views
                        ? data.views
                        : ""
                      : ""}{" "}
                    views
                  </p>
                  {data &&
                    (data.page === "property" || data.page === "job") && (
                      <p className="paragraph-margin-sm">
                        {" "}
                        Date Posted: {dateFormat(data.created_at)}
                      </p>
                    )}
                  <hr className="detail-custom-border" />
                  {data && (
                    <>
                      <div
                        id="detail_info"
                        className="detail_info"
                        style={{ display: "none" }}
                      >
                        {data && data.phone && (
                          <p className="detail-links">
                            <img src={Phone} alt={""} />
                            &nbsp;
                            <a
                              href={`tel:${
                                data ? (data.phone ? data.phone : "") : ""
                              }`}
                            >
                              {data ? (data.phone ? data.phone : "") : ""}
                            </a>
                          </p>
                        )}
                        {data && data.email && (
                          <p className="detail-links">
                            <a
                              href={`mailto:${data.email}`}
                              className="text-decorate-none"
                            >
                              <img src={Mail} alt={""} />
                              &nbsp;{data ? (data.email ? data.email : "") : ""}
                            </a>
                          </p>
                        )}
                        {data && data.website && (
                          <p className="detail-links">
                            <a
                              href={data.website}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-decorate-none"
                            >
                              <img src={Web} alt={""} />
                              &nbsp;
                              {data ? (data.website ? data.website : "") : ""}
                            </a>
                          </p>
                        )}
                      </div>
                      <button
                        id="detail_info_button"
                        onClick={(event) =>
                          detailInfo(
                            event,
                            data ? data.category_id : 0,
                            data ? data.id : 0
                          )
                        }
                        className="btn-detail-danger"
                      >
                        Show contact details
                      </button>
                    </>
                  )}
                  <hr className="detail-custom-border" />
                  <div className="hidden-md hidden-sm">
                    <h6 className="detail-heading">
                      {data ? (data.name ? data.name : "") : ""}
                    </h6>
                    <p
                      className="detail-desc"
                      dangerouslySetInnerHTML={{
                        __html: data
                          ? data.detail
                            ? data.detail.replace(/\n/g, "<br />")
                            : ""
                          : "",
                      }}
                    />
                    <div className="row">
                      {data && data.property_type && (
                        <div className="col-6">
                          <strong>Property Type: </strong>
                          {data
                            ? data.property_type
                              ? data.property_type
                              : ""
                            : ""}
                        </div>
                      )}
                      {data && data.type && (
                        <div className="col-6">
                          <strong>Job Type: </strong>
                          {data ? (data.type ? data.type : "") : ""}
                        </div>
                      )}
                      {data && data.level && (
                        <div className="col-6">
                          <strong>Job Level: </strong>
                          {data ? (data.level ? data.level : "") : ""}
                        </div>
                      )}
                      {data && data.salary_type && (
                        <div className="col-6">
                          <strong>Salary Type: </strong>
                          {data
                            ? data.salary_type
                              ? data.salary_type
                              : ""
                            : ""}
                          {data &&
                            data.salary_value &&
                            `(£${data.salary_value})`}
                        </div>
                      )}
                      {data && data.sharing && (
                        <div className="col-6">
                          <strong>Sharing: </strong>
                          {data ? (data.sharing ? data.sharing : "") : ""}
                        </div>
                      )}
                      {data && data.pets && (
                        <div className="col-6">
                          <strong>Pets: </strong>
                          {data ? (data.pets ? data.pets : "") : ""}
                        </div>
                      )}
                      {data && data.gender_preference && (
                        <div className="col-6">
                          <strong>Gender Preference: </strong>
                          {data
                            ? data.gender_preference
                              ? data.gender_preference
                              : ""
                            : ""}
                        </div>
                      )}
                      {data && data.deposit_required && (
                        <div className="col-6">
                          <strong>Deposit Required: </strong>
                          {data
                            ? data.deposit_required
                              ? data.deposit_required
                              : ""
                            : ""}
                          {data &&
                            data.deposit_value &&
                            `(£${data.deposit_value})`}
                        </div>
                      )}
                      {data && data.amount && (
                        <div className="col-6">
                          <strong>Monthly Rent / Price: </strong>
                          {data ? (data.amount ? `£${data.amount}` : "") : ""}
                        </div>
                      )}
                      {data && data.bills_included && (
                        <div className="col-6">
                          <strong>Bills Included: </strong>
                          {data
                            ? data.bills_included
                              ? data.bills_included
                              : ""
                            : ""}
                        </div>
                      )}
                      {data && data.tax_included && (
                        <div className="col-6">
                          <strong>Council Tax Included: </strong>
                          {data
                            ? data.tax_included
                              ? data.tax_included
                              : ""
                            : ""}
                        </div>
                      )}
                    </div>
                  </div>
                  {data && data.fb_link && (
                    <span className="detail-social-links">
                      <a
                        href={data.fb_link}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="list-unstyled"
                      >
                        <img src={FB} alt={""} />
                      </a>
                    </span>
                  )}
                  {data && data.insta_link && (
                    <span className="detail-social-links">
                      <a
                        href={data.insta_link}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="list-unstyled"
                      >
                        <img src={Instagram} alt={""} />
                      </a>
                    </span>
                  )}
                  {data && data.twitter_link && (
                    <span className="detail-social-links">
                      <a
                        href={data.twitter_link}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="list-unstyled"
                      >
                        <img src={Twitter} alt={""} />
                      </a>
                    </span>
                  )}
                  {data && data.youtube_link && (
                    <span className="detail-social-links">
                      <a
                        href={data.youtube_link}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="list-unstyled"
                      >
                        <img src={YouTube} alt={""} />
                      </a>
                    </span>
                  )}
                  {data && data.linkedin_link && (
                    <span className="detail-social-links">
                      <a
                        href={data.linkedin_link}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="list-unstyled"
                      >
                        <img src={Linkedin} alt={""} />
                      </a>
                    </span>
                  )}
                  {data && data.tripadvisor_link && (
                    <span className="detail-social-links">
                      <a
                        href={data.tripadvisor_link}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="list-unstyled"
                      >
                        <img src={Tripadvisor} alt={""} />
                      </a>
                    </span>
                  )}
                  <p>
                    <button
                      id="copylinkbtn"
                      className="btn-copy-pink"
                      onClick={handleCopy}
                    >
                      <img src={Degree45} alt={""} />
                      &nbsp;Copy link to this page
                    </button>
                  </p>
                  <div className="card detail-card">
                    <div className="card-body">
                      <h5 className="detail-card-title">Stay Safe</h5>
                      <p className="detail-card-text">
                        Be wary of scammers.
                        <br />
                        Ask as many questions as you need to before you decide
                        on any deal.
                      </p>
                      <Link
                        to={`/contact?type=report&url=${reportUrl}`}
                        onClick={handleGoTop}
                        className="btn-detail-light"
                      >
                        Report this listing
                      </Link>
                    </div>
                  </div>
                  {/* <div className='text-center'>
                            <div className='container'>
                                <img src={adImage} alt='...'/>
                            </div>
                        </div> */}
                </div>
              </div>
            </div>
          </div>
          {data && (
            <Similar
              item={data.id}
              page={data.page}
              category={data.category_id}
              location={data.location_id}
            />
          )}
          <Divider />
          {data && data.page === "business" && (
            <>
              <PopularCategories />
              <Divider />
            </>
          )}
          <AdvertiseWithUs />
        </>
      )}
    </>
  );
}
export default DetailWindow;
