import React from 'react'
import { ArrowLeftShort } from 'react-bootstrap-icons'

export default function NoRecordFound() {
  return (
    <>
    <div className='container '>
            <div className='inner-content'>
                <div className='col-lg-12 col-md-12 col-12 text-center'>
                <h3 className='no-result-found'>No results found</h3>
                <p className='try-else'>Please try searching for something else or <a href='https://sahisearch.com/manage/public/register'>request a listing</a></p>
                <a className="btn btn-search-refine hidden-md hidden-sm" href="/" data-bs-toggle="modal" data-bs-target="#exampleModal" >
                 <ArrowLeftShort size={30}/>&nbsp; &nbsp; Refine your search
                </a>
                </div>
            </div>
        </div>
        </>
  )
}
