import React from "react";
import { Link } from "react-router-dom";
import logo from "../../gallery/logo.png";
import { useEffect } from "react";
import { PersonCircle } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { setActiveLink } from "../../app/features/nav/navSlice";
import { logoutUser } from "../../app/features/user/userSlice.js";
//import axios from "axios";
require("../../css/navbar.css");
require("../../js/navbar.js");

function Navbar() {
  const dispatch = useDispatch();
  const activeItem = useSelector((state) => state.nav.activeLink);
  let user = useSelector((state) => state.user.user);

  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
    sessionStorage.setItem("user", localStorage.getItem("user"));
  }
  if (sessionStorage.getItem("user")) {
    user = JSON.parse(sessionStorage.getItem("user"));
  }
  function logOut() {
    dispatch(logoutUser());

    localStorage.removeItem("user");
    sessionStorage.removeItem("user");
  }
  useEffect(() => {
    // Retrieve the active link from storage on component mount
  }, [activeItem, user]);
  const mobileNav = function (event) {
    if (document.body.classList.contains("nav2-is-toggled")) {
      document.body.classList.remove("nav2-is-toggled");
    } else {
      document.body.classList.add("nav2-is-toggled");
    }
    if (document.body.classList.contains("body-no-scroll")) {
      document.body.classList.remove("body-no-scroll");
    } else {
      document.body.classList.add("body-no-scroll");
    }
    if (document.getElementById("ham").textContent === "menu") {
      document.getElementById("ham").textContent = "close";
    } else {
      document.getElementById("ham").textContent = "menu";
    }
  };
  function goToTop(activeLink) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(setActiveLink(activeLink));
  }
  /* async function goToAdmin(e, email) {
    e.preventDefault();
    await axios
      .request({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/api/sanctum/csrf-cookie`,
      })
      .then(async (sanRep) => {
        const form = document.createElement("form");
        form.method = "POST";
        form.action = "http://192.168.10.8/sahisearch/manage/public/site_login";

        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = "email";
        hiddenField.value = email;

        form.appendChild(hiddenField);

        document.body.appendChild(form);
        form.submit();
      })
      .catch(function (error) {
        console.error(error);
      });
  } */
  return (
    <>
      <nav className="navbar navbar-expand-lg nav-background sticky-top">
        <div className="container">
          <Link onClick={() => goToTop("home")} to="/" className="navbar-logo">
            <img src={logo} alt={"Sahi Search"} style={{ width: "150px" }} />
          </Link>
          <button className="navbar-toggler" type="button">
            <span className="hamburger material-icons" id="ham" onClick={mobileNav}>
              menu
            </span>
          </button>

          <div className="hidden-xs hidden-sm" style={{ width: "100%" }}>
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <Link onClick={() => goToTop("home")} className={activeItem === "home" ? "nav-link active" : "nav-link"} aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={() => goToTop("event")} className={activeItem === "event" ? "nav-link active" : "nav-link"} aria-current="page" to="/events">
                  Events
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={() => goToTop("business")} className={activeItem === "business" ? "nav-link active" : "nav-link"} aria-current="page" to="/search?category=1&location=1&page=1&limit=10&sort=null">
                  Businesses
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={() => goToTop("property")} className={activeItem === "property" ? "nav-link active" : "nav-link"} aria-current="page" to="/search?category=3&location=1&page=1&limit=10&sort=null&type=0&gender=3&ctax=0&sharing=0&deposit=0&bills=0">
                  Rooms for Rent
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={() => goToTop("job")} className={activeItem === "job" ? "nav-link active" : "nav-link"} aria-current="page" to="/search?category=2&location=1&page=1&limit=10&sort=null&title=&job=0&level=0&disclosed=0">
                  Jobs
                </Link>
              </li>
              {!user ? (
                <>
                  <li className="nav-item login-signup mt-2 ms-auto" style={{ marginRight: "20px" }}>
                    <a href="https://sahisearch.com/manage/public/login" className={"login-signup-link"}>
                      Log in
                    </a>
                    &nbsp;/&nbsp;
                    <a href="https://sahisearch.com/manage/public/register" className={"login-signup-link"}>
                      Sign up
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item login-signup mt-2 ms-auto" style={{ marginRight: "20px" }}>
                    <PersonCircle />
                    &nbsp;&nbsp;
                    {user.email}
                  </li>
                </>
              )}
              {!user ? (
                <li className="nav-item">
                  <a href="https://sahisearch.com/manage/public/register" type="button" className="ms-auto btn btn-nav-light hidden-xs hidden-sm">
                    Start listing for free
                  </a>
                </li>
              ) : (
                <li className="nav-item">
                  <button className="ms-auto btn btn-nav-light hidden-xs hidden-sm" onClick={logOut}>
                    Logout
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
      <div className="mobile-nav">
        <nav className="nav2-drill">
          <ul className="nav2-items nav2-level-1">
            <li className="nav2-item">
              <Link onClick={() => goToTop("home")} className={activeItem === "home" ? "nav2-link active" : "nav2-link"} aria-current="page" to="/">
                Home
              </Link>
            </li>
            <li className="nav2-item">
              <Link onClick={() => goToTop("event")} className={activeItem === "event" ? "nav2-link active" : "nav2-link"} aria-current="page" to="/events">
                Events
              </Link>
            </li>
            <li className="nav2-item">
              <Link onClick={() => goToTop("business")} className={activeItem === "business" ? "nav2-link active" : "nav2-link"} aria-current="page" to="/search?category=1&location=1&page=1&limit=10">
                Businesses
              </Link>
            </li>
            <li className="nav2-item">
              <Link onClick={() => goToTop("property")} className={activeItem === "property" ? "nav2-link active" : "nav2-link"} aria-current="page" to="/search?category=3&location=1&page=1&limit=10">
                Rooms for Rent
              </Link>
            </li>
            <li className="nav2-item">
              <Link onClick={() => goToTop("job")} className={activeItem === "job" ? "nav2-link active" : "nav2-link"} aria-current="page" to="/search?category=2&location=1&page=1&limit=10">
                Jobs
              </Link>
            </li>
            <li className="nav2-item">
              <a className="nav2-link " href="https://sahisearch.com/manage/public/login">
                Log in
              </a>
            </li>
            <li className="nav2-item">
              <a href="https://sahisearch.com/manage/public/register" className="nav2-link ">
                Sign up
              </a>
            </li>

            <li className="nav2-item">
              <a className="nav2-link " href="https://sahisearch.com/manage/public/register">
                List for FREE
              </a>
            </li>
            {/* <li className="nav2-item align-content-center">
                            <Link to='#' className=" nav2-link  mt-1">Advertise with us</Link>
                        </li> */}
          </ul>
        </nav>
      </div>
    </>
  );
}
export default Navbar;
