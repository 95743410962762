import React, { useEffect, useState } from "react";
import Category from "./Category";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ArrowDown } from "react-bootstrap-icons";

function PopularCategories() {
  const [data, setData] = useState();

  const location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  let queryLocation = 1;
  if (queryParams.get("location")) {
    queryLocation = queryParams.get("location");
  }

  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    const options = {
      method: "GET",
      url: process.env.REACT_APP_BASE_URL + "/api/categories",
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
      },
      params: {
        page: 1,
        limit: 300,
      },
    };

    await axios
      .request(options)
      .then(function (response) {
        setData(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  const handleShow = (e) => {
    var popularCat = document.getElementById("popularCat");

    var aTags = popularCat.querySelectorAll("a");

    // Loop through all the <a> tags and change their class
    for (var i = 0; i < aTags.length; i++) {
      // Change the class attribute of the <a> tag
      aTags[i].classList.remove("hidden-xs");
      aTags[i].classList.remove("hidden-sm");
    }
    e.target.classList.add("hidden-xs");
    e.target.classList.add("hidden-sm");
  };

  /*console.log(data)*/
  return (
    <div className="container popular-category-container">
      <div className="inner-content">
        <h6 className="popular-category-title">Popular categories</h6>
        <div className="row popular-category" id="popularCat">
          {data
            ? data.map((element, index) => {
                if (index > 9) {
                  return (
                    <Category
                      hiddenClass={"hidden-xs hidden-sm"}
                      //active = {parseInt(queryCategory) === parseInt(element.id) ? 1:2}
                      category={element.name}
                      count={element.count}
                      to={`/search?category=${element.id}&location=${queryLocation}&page=1&limit=6`}
                      key={"category_" + index}
                    />
                  );
                } else {
                  return (
                    <Category
                      //active = {parseInt(queryCategory) === parseInt(element.id) ? 1:2}
                      category={element.name}
                      count={element.count}
                      to={`/search?category=${element.id}&location=${queryLocation}&page=1&limit=6`}
                      key={"category_" + index}
                    />
                  );
                }
              })
            : false}
          <div
            className="d-flex justify-content-center align-items-center hidden-md"
            style={{ marginTop: "16px" }}
          >
            <button
              className="btn btn-advertise-light btn-sm hidden-md"
              onClick={handleShow}
            >
              Show All <ArrowDown size={18} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PopularCategories;
