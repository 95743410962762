import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeLink: "home",
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setActiveLink: (state, actions) => {
      state.activeLink = actions.payload;
    },
  },
});

export const { setActiveLink } = navSlice.actions;

export default navSlice.reducer;
