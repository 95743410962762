import React, { useState, useEffect } from "react";
import TabEvents from "./TabEvents";
import axios from "axios";

function EventsTab(props) {
  const [data, setData] = useState();
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    const options = {
      method: "GET",
      url: process.env.REACT_APP_BASE_URL + "/api/categories",
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
      },
      params: {
        parent: 4,
        page: 1,
        limit: 50,
      },
    };

    await axios
      .request(options)
      .then(function (response) {
        setData(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  return (
    <div className="event-tabs">
      <ul
        className="nav nav-pills mb-3 justify-content-center"
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <a
            href="/"
            className="nav-link active"
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            All
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            href="/"
            className="nav-link"
            id="pills-week-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-week"
            role="tab"
            aria-controls="pills-week"
            aria-selected="false"
          >
            This week
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            href="/"
            className="nav-link"
            id="pills-month-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-month"
            role="tab"
            aria-controls="pills-month"
            aria-selected="false"
          >
            This month
          </a>
        </li>
        {data
          ? data.map((element, index) => {
              return (
                <li className="nav-item" role="presentation" key={index}>
                  <a
                    href="/"
                    className="nav-link"
                    id={`pills-${element.name}-tab`}
                    data-bs-toggle="pill"
                    data-bs-target={`#pills-${element.name}`}
                    role="tab"
                    aria-controls={`pills-${element.name}`}
                    aria-selected="false"
                  >
                    {element.name}
                  </a>
                </li>
              );
            })
          : false}
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <TabEvents locationId={props.locationId ? props.locationId : ""} />
        </div>
        <div
          className="tab-pane fade"
          id="pills-week"
          role="tabpanel"
          aria-labelledby="pills-week-tab"
        >
          <TabEvents
            locationId={props.locationId ? props.locationId : ""}
            report="week"
          />
        </div>
        <div
          className="tab-pane fade"
          id="pills-month"
          role="tabpanel"
          aria-labelledby="pills-month-tab"
        >
          <TabEvents
            locationId={props.locationId ? props.locationId : ""}
            report="month"
          />
        </div>
        {data
          ? data.map((element, index) => {
              return (
                <div
                  key={index}
                  className="tab-pane fade"
                  id={`pills-${element.name}`}
                  role="tabpanel"
                  aria-labelledby={`pills-${element.name}-tab`}
                >
                  <TabEvents
                    locationId={props.locationId ? props.locationId : ""}
                    categoryId={element.id}
                  />
                </div>
              );
            })
          : false}
      </div>
    </div>
  );
}

export default EventsTab;
