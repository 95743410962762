import React, { useEffect, useState } from "react";
import SelectCustom from "./SelectCustom";
import Card from "./Card";
import { Link, useHistory, useLocation } from "react-router-dom";
//import NativeAd from "./NativeAd";
import Divider from "./Divider";
import axios from "axios";
import Home from "../../gallery/icons/home.png";
import NoRecordFound from "./NoRecordFound";
import BusinessSearch from "../business/BusinessSearch";
import Filter from "../../gallery/icons/filter.png";
import DefaultRoom from "../../gallery/default/placeholder.png";
import PropertySearch from "../property/PropertySearch";
import { initializeTagManager } from "../../TagManager";
import Pagination from "./Pagination";
import JobSearch from "../job/JobSearch";
import BusinessSearchModel from "../business/BusinessSearchModel";
import JobSerachModel from "../job/JobSerachModel";
import PropertySearchModel from "../property/PropertySearchModel";
import PopularCategories from "./PopularCategories";
import AdvertiseWithUs from "../template/AdvertiseWithUs";
import EventWithUs from "../events/EventWithUs";
import EventFilters from "../events/EventFilters";
import EventCard from "../events/EventCard";
import DefaultBL from "../../gallery/default/defaultBusinessLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { setActiveLink } from "../../app/features/nav/navSlice";
import { BeatLoader } from "react-spinners";

function SearchListing() {
  const dispatch = useDispatch();
  function toggleMobilenav() {
    var body = document.body;
    var hasClass = body.classList.contains("nav2-is-toggled");
    if (hasClass) {
      document.body.classList.remove("nav2-is-toggled");
      document.body.classList.remove("body-no-scroll");
      document.getElementById("ham").textContent = "menu";
    }
  }
  //used history hook to redirect the page
  const history = useHistory();
  //using location hook to get params from URL
  const location = useLocation();
  var nearestLocation = 0;
  var currentLocationFlag = 0;
  var pillsimilar = null;
  let dataPage = useSelector((state) => state.nav.activeLink);
  let advertise = ""; // for dynamic advertisement portion
  let nearBy = ""; // for dynamic near by services/events/jobs etc

  let queryParams = new URLSearchParams(location.search);
  let queryCategory = queryParams.get("category");
  let queryLocation = queryParams.get("location");
  let queryPage = queryParams.get("page");
  let queryLimit = queryParams.get("limit");
  let querySort = queryParams.get("sort");

  let queryType = "0";
  if (queryParams.get("type")) {
    queryType = queryParams.get("type");
  }
  let queryCTax = 0;
  if (queryParams.get("ctax")) {
    queryCTax = queryParams.get("ctax");
  }
  let querySharing = 0;
  if (queryParams.get("sharing")) {
    querySharing = queryParams.get("sharing");
  }
  let queryDeposit = 0;
  if (queryParams.get("deposit")) {
    queryDeposit = queryParams.get("deposit");
  }
  let queryGender = "3";
  if (queryParams.get("gender")) {
    queryGender = queryParams.get("gender");
  }
  let queryBills = 0;
  if (queryParams.get("bills")) {
    queryBills = queryParams.get("bills");
  }
  let queryTitle = "";
  if (queryParams.get("title")) {
    queryTitle = queryParams.get("title");
  }
  let queryJob = 0;
  if (queryParams.get("job")) {
    queryJob = queryParams.get("job");
  }
  let queryLevel = 0;
  if (queryParams.get("level")) {
    queryLevel = queryParams.get("level");
  }
  let queryDisclosed = 0;
  if (queryParams.get("disclosed")) {
    queryDisclosed = queryParams.get("disclosed");
  }
  let querySafe = 0;
  if (queryParams.get("safe")) {
    querySafe = queryParams.get("safe");
  }
  let queryFree = 0;
  if (queryParams.get("free")) {
    queryFree = queryParams.get("free");
  }

  let categorySelectedOption = "";
  let locationSelectedOption = "";
  let breadcrumbLocation = "";

  //setting states for categories, locations and search
  const [parentCategory, setParentCategory] = useState();
  const [data, setData] = useState(null);
  const [paginationData, setPaginationData] = useState();
  const [search, setSearch] = useState({ categories: null, locations: null });

  useEffect(() => {
    setData(null);
    initializeTagManager();
    toggleMobilenav();
    fetchCategories();
    fetchLocations();
    fetchSearch();
    fetchCategory();
  }, [
    queryCategory,
    queryLocation,
    queryPage,
    queryLimit,
    querySort,
    queryType,
    queryGender,
    queryCTax,
    querySharing,
    queryDeposit,
    queryBills,
    queryTitle,
    queryJob,
    queryLevel,
    queryDisclosed,
    querySafe,
    queryFree,
    dataPage,
  ]);
  async function fetchCategories() {
    await axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_BASE_URL + "/api/categories",
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
        params: {
          page: 1,
          limit: 300,
        },
      })
      .then(function (response) {
        setSearch((prevSearch) => ({
          ...prevSearch,
          categories: response.data.data,
        }));
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  async function fetchLocations() {
    await axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_BASE_URL + "/api/locations",
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
        params: {
          page: 1,
          limit: 300,
        },
      })
      .then(function (response) {
        setSearch((prevSearch) => ({
          ...prevSearch,
          locations: response.data.data,
        }));
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  async function fetchSearch() {
    let Ourparams = {
      category: queryCategory,
      location: queryLocation,
      page: queryPage,
      limit: queryLimit,
      sort: querySort,
      type: queryType,
      gender: queryGender,
      ctax: queryCTax,
      sharing: querySharing,
      deposit: queryDeposit,
      bills: queryBills,
      title: queryTitle,
      job: queryJob,
      level: queryLevel,
      disclosed: queryDisclosed,
    };

    await axios
      .request({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/api/search`,
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
        params: Ourparams,
      })
      .then(function (response) {
        setData(response.data.data);
        const { data, ...updatedData } = response.data;
        setPaginationData(updatedData);
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  async function fetchCategory() {
    await axios
      .request({
        method: "GET",
        url:
          process.env.REACT_APP_BASE_URL + "/api/categories/" + queryCategory,
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
        params: {
          page: 1,
          limit: 300,
        },
      })
      .then(function (response) {
        setParentCategory(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  function handlePropSearch(queryParam, paramType) {
    if (paramType === "property") {
      queryType = queryParam;
    } else if (paramType === "gender") {
      queryGender = queryParam;
    } else if (paramType === "ctax") {
      queryCTax = queryParam;
    } else if (paramType === "sharing") {
      querySharing = queryParam;
    } else if (paramType === "deposit") {
      queryDeposit = queryParam;
    } else if (paramType === "bills") {
      queryBills = queryParam;
    } else if (paramType === "title") {
      queryTitle = queryParam;
    } else if (paramType === "job") {
      queryJob = queryParam;
    } else if (paramType === "level") {
      queryLevel = queryParam;
    } else if (paramType === "disclosed") {
      queryDisclosed = queryParam;
    } else if (paramType === "sort") {
      querySort = queryParam;
    } else if (paramType === "safe") {
      querySafe = queryParam;
    } else if (paramType === "free") {
      queryFree = queryParam;
    } else if (paramType === "category") {
      queryCategory = queryParam;
    }
    if (dataPage === "business") {
      history.push(
        `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}`
      );
    } else if (dataPage === "job") {
      history.push(
        `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}&title=${queryTitle}&job=${queryJob}&level=${queryLevel}&disclosed=${queryDisclosed}`
      );
    } else if (dataPage === "property") {
      history.push(
        `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}&type=${queryType}&gender=${queryGender}&ctax=${queryCTax}&sharing=${querySharing}&deposit=${queryDeposit}&bills=${queryBills}`
      );
    } else if (dataPage === "event") {
      history.push(
        `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}&safe=${querySafe}&free=${queryFree}`
      );
    }
  }
  const handleRedirect = (value, type, value2 = null, type2 = null) => {
    queryPage = 1;
    if (type === "category") {
      queryCategory = value;
    }

    if (type === "location") {
      queryLocation = value;
    }
    if (type2) {
      queryLocation = value2;
    }

    if (dataPage === "business") {
      history.push(
        `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}`
      );
    } else if (dataPage === "job") {
      history.push(
        `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}&title=${queryTitle}&job=${queryJob}&level=${queryLevel}&disclosed=${queryDisclosed}`
      );
    } else if (dataPage === "property") {
      history.push(
        `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}&type=${queryType}&gender=${queryGender}&ctax=${queryCTax}&sharing=${querySharing}&deposit=${queryDeposit}&bills=${queryBills}`
      );
    } else if (dataPage === "event") {
      history.push(
        `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}&safe=${querySafe}&free=${queryFree}`
      );
    }
  };
  const handlePagination = (event, p) => {
    event.preventDefault();
    queryPage = p;
    if (dataPage === "business") {
      history.push(
        `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}`
      );
    } else if (dataPage === "job") {
      history.push(
        `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}&title=${queryTitle}&job=${queryJob}&level=${queryLevel}&disclosed=${queryDisclosed}`
      );
    } else if (dataPage === "property") {
      history.push(
        `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}&type=${queryType}&gender=${queryGender}&ctax=${queryCTax}&sharing=${querySharing}&deposit=${queryDeposit}&bills=${queryBills}`
      );
    } else if (dataPage === "event") {
      history.push(
        `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}&safe=${querySafe}&free=${queryFree}`
      );
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleSort = (event, s) => {
    event.preventDefault();
    querySort = s;
    queryPage = 1;
    if (dataPage === "business") {
      history.push(
        `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}`
      );
    } else if (dataPage === "job") {
      history.push(
        `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}&title=${queryTitle}&job=${queryJob}&level=${queryLevel}&disclosed=${queryDisclosed}`
      );
    } else if (dataPage === "property") {
      history.push(
        `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}&type=${queryType}&gender=${queryGender}&ctax=${queryCTax}&sharing=${querySharing}&deposit=${queryDeposit}&bills=${queryBills}`
      );
    } else if (dataPage === "event") {
      history.push(
        `/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}&safe=${querySafe}&free=${queryFree}`
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  function findRecordById(records, id) {
    return records.find((x) => parseInt(x.id) === parseInt(id));
  }
  if (search) {
    if (search.categories && queryCategory) {
      var recordCat = search.categories.find(
        (c) => parseInt(c.id) === parseInt(queryCategory)
      );
      if (recordCat) {
        categorySelectedOption = {
          label: recordCat.name,
          value: queryCategory,
        };
      }
    }
    if (search.locations && queryLocation) {
      var recordLoc = findRecordById(search.locations, queryLocation);
      if (recordLoc) {
        locationSelectedOption = {
          label: recordLoc.name,
          value: queryLocation,
        };
        if (parseInt(queryLocation) === parseInt(1)) {
          breadcrumbLocation = locationSelectedOption.label;
          locationSelectedOption.label = "Choose your location";
        } else {
          breadcrumbLocation = locationSelectedOption.label;
        }
      }
    }
  }

  if (parentCategory) {
    if (
      parseInt(parentCategory.parent) === 4 ||
      parseInt(queryCategory) === 4
    ) {
      dispatch(setActiveLink("event"));
      advertise = <EventWithUs />;
    } else if (
      parseInt(parentCategory.parent) === 5 ||
      parseInt(queryCategory) === 5 ||
      parseInt(queryCategory) === 1
    ) {
      dispatch(setActiveLink("business"));
      advertise = <AdvertiseWithUs />;
    } else if (
      parseInt(parentCategory.parent) === 3 ||
      parseInt(queryCategory) === 3
    ) {
      dispatch(setActiveLink("property"));
      advertise = <AdvertiseWithUs />;
    } else if (
      parseInt(parentCategory.parent) === 2 ||
      parseInt(queryCategory) === 2
    ) {
      dispatch(setActiveLink("job"));
      advertise = <AdvertiseWithUs />;
    }
  }

  return (
    <>
      {!data && (
        <div className="loader-in-component-body">
          <BeatLoader
            color="#C6153B"
            onLoad={window.scrollTo({ top: 0, behavior: "smooth" })}
          />
        </div>
      )}
      {search && data && (
        <div className="container">
          <div className="inner-content">
            <div className="row bread-and-sort hidden-xs">
              <div className="col-lg-6 col-md-8 col-sm-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <img src={Home} alt="" />
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link
                        to={`/search?category=${queryCategory}&location=${queryLocation}&page=${queryPage}&limit=${queryLimit}&sort=${querySort}`}
                        className="decorate-none"
                      >
                        {dataPage
                          ? dataPage === "job"
                            ? "Jobs"
                            : dataPage === "property"
                            ? "Property"
                            : dataPage === "business"
                            ? "Business"
                            : dataPage === "event"
                            ? "Event"
                            : ""
                          : ""}
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <span className="breadcrumb-active">
                        {categorySelectedOption
                          ? `Nepalese ${categorySelectedOption.label}`
                          : "category"}
                      </span>{" "}
                      in{" "}
                      <span className="breadcrumb-active">
                        {breadcrumbLocation ? breadcrumbLocation : ""}
                      </span>
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="col-lg-6 col-md-4 col-sm-12">
                <div className="refine hidden-xs hidden-md">
                  <section className="refine-section float-end">
                    <a
                      className="refine-xxs refine-text"
                      href="/"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <span className="hidden-xxs">Refine</span> &nbsp;
                      <img src={Filter} alt="" />
                    </a>
                  </section>
                </div>
                <div className="dropdown hidden-sm">
                  <section className="sort-by-section float-end">
                    <span className="sort-by">sort by</span>
                    <a
                      href="/"
                      className="decorate-none dropdown-toggle dropdown-sort"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {querySort
                        ? querySort !== "null" && querySort === "name"
                          ? "A - Z" //querySort.charAt(0).toUpperCase() + querySort.slice(1)
                          : "Relevance"
                        : "Relevance"}
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          onClick={(event) => handleSort(event, "name")}
                          className={
                            querySort
                              ? querySort === "name"
                                ? "dropdown-item active-link"
                                : "dropdown-item"
                              : "dropdown-item"
                          }
                          href="/"
                        >
                          A - Z
                        </a>
                        <a
                          onClick={(event) => handleSort(event, "null")}
                          className={
                            querySort
                              ? querySort === "null"
                                ? "dropdown-item active-link"
                                : "dropdown-item"
                              : "dropdown-item"
                          }
                          href="/"
                        >
                          Relevance
                        </a>
                      </li>
                    </ul>
                  </section>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-12 ">
                <div className="row">
                  <div className="showing-result">
                    <div className="row">
                      <div className="col-9 p-0 m-0">
                        {paginationData && paginationData.total > 0 && (
                          <>
                            Showing&nbsp;
                            {paginationData
                              ? paginationData.current_page *
                                  paginationData.per_page +
                                1 -
                                paginationData.per_page
                              : ""}
                            -
                            {paginationData
                              ? paginationData.total < paginationData.per_page
                                ? paginationData.total
                                : paginationData.current_page *
                                  paginationData.per_page
                              : ""}
                            &nbsp;of&nbsp;
                            {paginationData ? paginationData.total : ""} results
                          </>
                        )}
                      </div>
                      <div className="col-3 p-0 hidden-sm hidden-md float-end">
                        <a
                          className="refine-xxs refine-text"
                          href="/"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <span className="hidden-xxs">Refine</span> &nbsp;
                          <img src={Filter} alt="" />
                        </a>
                      </div>
                      {dataPage === "business" &&
                        locationSelectedOption &&
                        categorySelectedOption && (
                          <BusinessSearchModel
                            pageTitle={`Nepalese ${categorySelectedOption.label} in ${breadcrumbLocation}`}
                            categories={search ? search.categories : false}
                            locations={search ? search.locations : false}
                            categorySelectedOption={categorySelectedOption}
                            locationSelectedOption={locationSelectedOption}
                          />
                        )}
                      {dataPage === "job" && locationSelectedOption && (
                        <JobSerachModel
                          pageTitle={`Nepalese ${categorySelectedOption.label} in ${breadcrumbLocation}`}
                          title={queryTitle ? queryTitle : ""}
                          job={queryJob ? queryJob : 0}
                          level={queryLevel ? queryLevel : 0}
                          disclosed={queryDisclosed ? queryDisclosed : 0}
                          sort={querySort ? querySort : null}
                          locations={search ? search.locations : false}
                          locationSelectedOption={locationSelectedOption}
                        />
                      )}
                      {dataPage === "property" && locationSelectedOption && (
                        <PropertySearchModel
                          pageTitle={`Nepalese ${categorySelectedOption.label} in ${breadcrumbLocation}`}
                          type={queryType ? queryType : 0}
                          gender={queryGender ? queryGender : 3}
                          sort={querySort ? querySort : null}
                          ctax={queryCTax ? queryCTax : 0}
                          sharing={querySharing ? querySharing : 0}
                          deposit={queryDeposit ? queryDeposit : 0}
                          bills={queryBills ? queryBills : 0}
                          locations={search ? search.locations : false}
                          locationSelectedOption={locationSelectedOption}
                        />
                      )}
                    </div>
                  </div>
                  <div className="toggle-search hidden-xs hidden-sm">
                    {categorySelectedOption &&
                      queryCategory &&
                      dataPage === "business" && (
                        <div className="col-lg-12 col-md-12 mb-3">
                          <SelectCustom
                            id={"category_select"}
                            options={search ? search.categories : false}
                            handle={handleRedirect}
                            type="category"
                            selectedOption={categorySelectedOption}
                          />
                        </div>
                      )}
                    {locationSelectedOption &&
                      breadcrumbLocation &&
                      queryLocation && (
                        <div className="col-lg-12 col-md-12 mb-3">
                          <SelectCustom
                            id={"location_select"}
                            options={search ? search.locations : false}
                            type="location"
                            handle={handleRedirect}
                            selectedOption={locationSelectedOption}
                          />
                        </div>
                      )}
                    {/* <div className="col-lg-12 col-md-12 ">
                    <button
                      onClick={handleRedirect}
                      className="btn-search-list-danger"
                    >
                      <Search /> Search
                    </button>
                  </div> */}
                  </div>
                </div>
                {dataPage === "business" && (
                  <>
                    <BusinessSearch
                      pageTitle={`Nepalese ${categorySelectedOption.label} in ${breadcrumbLocation}`}
                      searchCategories={
                        search.categories ? search.categories : ""
                      }
                      queryCategory={queryCategory ? queryCategory : ""}
                    />
                  </>
                )}
                {dataPage === "job" && (
                  <>
                    <JobSearch
                      pageTitle={`Nepalese ${categorySelectedOption.label} in ${breadcrumbLocation}`}
                      title={queryTitle ? queryTitle : ""}
                      job={queryJob ? queryJob : "all"}
                      level={queryLevel ? queryLevel : "all"}
                      disclosed={queryDisclosed ? queryDisclosed : 0}
                      sort={querySort ? querySort : null}
                      handle={handlePropSearch}
                    />
                  </>
                )}
                {dataPage === "property" && (
                  <>
                    <PropertySearch
                      pageTitle={`Nepalese ${categorySelectedOption.label} in ${breadcrumbLocation}`}
                      type={queryType ? queryType : 0}
                      ctax={queryCTax ? queryCTax : 0}
                      sharing={querySharing ? querySharing : 0}
                      deposit={queryDeposit ? queryDeposit : 0}
                      gender={queryGender ? queryGender : 3}
                      bills={queryBills ? queryBills : 0}
                      handle={handlePropSearch}
                    />
                  </>
                )}
                {dataPage === "event" && (
                  <>
                    <EventFilters
                      pageTitle={`Nepalese ${categorySelectedOption.label} in ${breadcrumbLocation}`}
                      safe={querySafe ? querySafe : 0}
                      category={queryCategory ? queryCategory : 4}
                      time={0}
                      free={queryFree ? queryFree : 0}
                      handle={handlePropSearch}
                    />
                  </>
                )}
              </div>
              <div className="col-lg-9 col-md-12">
                <div className="row">
                  {data
                    ? data.map((element, index, lastIndex) => {
                        if (element.page === "business") {
                          pillsimilar =
                            element.category.charAt(0).toUpperCase() +
                            element.category.slice(1);
                        } else if (element.page === "job") {
                          pillsimilar =
                            element.type.charAt(0).toUpperCase() +
                            element.type.slice(1);
                        } else if (element.page === "property") {
                          pillsimilar =
                            element.property_type.charAt(0).toUpperCase() +
                            element.property_type.slice(1);
                        } else if (element.page === "event") {
                          pillsimilar =
                            element.type.charAt(0).toUpperCase() +
                            element.type.slice(1);
                        }
                        if (
                          parseInt(queryLocation) !==
                          parseInt(element.location_id)
                        ) {
                          nearestLocation = nearestLocation + 1;
                        }
                        if (
                          parseInt(queryLocation) ===
                          parseInt(element.location_id)
                        ) {
                          currentLocationFlag = currentLocationFlag + 1;
                        }

                        if (dataPage === "property") {
                          nearBy = "Similar properties near by";
                        } else if (dataPage === "event") {
                          nearBy = "Similar events near by";
                        } else if (dataPage === "job") {
                          nearBy = "Similar jobs near by";
                        } else if (dataPage === "business") {
                          nearBy = "Similar services near by";
                        }

                        if (dataPage === "property" || dataPage === "event") {
                          return (
                            <React.Fragment key={index}>
                              {parseInt(currentLocationFlag) === parseInt(0) &&
                                parseInt(queryLocation) !== parseInt(1) &&
                                parseInt(index) === parseInt(0) &&
                                parseInt(queryPage) === parseInt(1) && (
                                  <NoRecordFound />
                                )}

                              {parseInt(nearestLocation) === parseInt(1) &&
                                parseInt(queryLocation) !== parseInt(1) && (
                                  <div>
                                    <Divider />
                                    <h3 className="content-sub-heading">
                                      {nearBy}&nbsp;
                                      <span className="similar-location">
                                        {breadcrumbLocation}
                                      </span>
                                    </h3>
                                  </div>
                                )}
                              <div
                                className="col-lg-6 col-md-6 col-sm-12"
                                key={index + "" + element.name}
                              >
                                {dataPage === "property" && (
                                  <Card
                                    //imgaeWidth="345px"
                                    //imageHeight="212px"
                                    align={"align-items-center"}
                                    justifycontent={"center"}
                                    image={
                                      element.image_name
                                        ? element.image_path
                                        : DefaultRoom
                                    }
                                    isImage={
                                      element.image_name
                                        ? element.image_path
                                        : false
                                    }
                                    title={element.name ? element.name : false}
                                    location={
                                      element.location
                                        ? element.location
                                        : false
                                    }
                                    postalcode={
                                      element.postal_code
                                        ? element.postal_code
                                        : false
                                    }
                                    page={element.page ? element.page : ""}
                                    type={pillsimilar}
                                    salary={
                                      element.amount ? element.amount : false
                                    }
                                    desc={
                                      element.detail ? element.detail : false
                                    }
                                    detail_url={
                                      element.page + "/" + element.slug
                                    }
                                    views={element.views}
                                  />
                                )}
                                {dataPage === "event" && (
                                  <EventCard
                                    imageHeight="370px"
                                    image={
                                      element.image_name
                                        ? element.image_path
                                        : false
                                    }
                                    title={element.name ? element.name : ""}
                                    location={
                                      element.location ? element.location : ""
                                    }
                                    postalcode={
                                      element.postal_code
                                        ? element.postal_code
                                        : ""
                                    }
                                    page={element.page ? element.page : ""}
                                    type={
                                      element.category
                                        ? element.category
                                            .charAt(0)
                                            .toUpperCase() +
                                          element.category.slice(1)
                                        : ""
                                    }
                                    startDate={element.start_date}
                                    showStartDate={element.show_start_date}
                                    endDate={element.end_date}
                                    adultOnly={element.adult_event}
                                    detail_url={
                                      element.page + "/" + element.slug
                                    }
                                    views={element.views}
                                    price={
                                      element.tickets.length > 0
                                        ? element.tickets[0].price
                                        : null
                                    }
                                  />
                                )}
                                {/* { index === 1  &&  <div>
                                    <NativeAd/>
                                    <Divider/>
                                </div> }
                                { lastIndex.length-1 < 1  &&  <div>
                                    <NativeAd/>
                                    <Divider/>
                                </div> } */}
                              </div>
                            </React.Fragment>
                          );
                        } else if (dataPage === "business") {
                          return (
                            <React.Fragment>
                              {parseInt(currentLocationFlag) === parseInt(0) &&
                                parseInt(queryLocation) !== parseInt(1) &&
                                parseInt(index) === parseInt(0) &&
                                parseInt(queryPage) === parseInt(1) && (
                                  <NoRecordFound />
                                )}

                              {parseInt(nearestLocation) === parseInt(1) &&
                                parseInt(queryLocation) !== parseInt(1) && (
                                  <div>
                                    <Divider />
                                    <h3 className="content-sub-heading">
                                      {nearBy}&nbsp;
                                      <span className="similar-location">
                                        {breadcrumbLocation}
                                      </span>
                                    </h3>
                                  </div>
                                )}
                              <div
                                className="col-lg-6 col-md-6 col-sm-12"
                                key={index + "" + element.name}
                              >
                                <Card
                                  imgaeWidth="305px"
                                  imageHeight="75px"
                                  align={"align-items-start"}
                                  justifycontent={"left"}
                                  image={
                                    element.image_thumbnail_path
                                      ? element.image_thumbnail_path
                                      : DefaultBL
                                  }
                                  title={element.name ? element.name : false}
                                  location={
                                    element.location ? element.location : false
                                  }
                                  postalcode={
                                    element.postal_code
                                      ? element.postal_code
                                      : false
                                  }
                                  page={element.page ? element.page : ""}
                                  type={pillsimilar}
                                  salary={
                                    element.salary_value
                                      ? element.salary_value
                                      : false
                                  }
                                  desc={element.detail ? element.detail : false}
                                  category_id={element.category_id}
                                  detail_url={element.page + "/" + element.slug}
                                  views={element.views}
                                />
                                {/* { index === 1  &&  <div>
                                    <NativeAd/>
                                    <Divider/>
                                </div> }
                                { lastIndex.length-1 < 1  &&  <div>
                                    <NativeAd/>
                                    <Divider/>
                                </div> } */}
                              </div>
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <React.Fragment key={index}>
                              {parseInt(currentLocationFlag) === parseInt(0) &&
                                parseInt(queryLocation) !== parseInt(1) &&
                                parseInt(index) === parseInt(0) &&
                                parseInt(queryPage) === parseInt(1) && (
                                  <NoRecordFound />
                                )}

                              {parseInt(nearestLocation) === parseInt(1) &&
                                parseInt(queryLocation) !== parseInt(1) && (
                                  <div>
                                    <Divider />
                                    <h3 className="content-sub-heading">
                                      {nearBy}&nbsp;
                                      <span className="similar-location">
                                        {breadcrumbLocation}
                                      </span>
                                    </h3>
                                  </div>
                                )}
                              <div
                                className="col-lg-12 col-md-12 col-sm-12"
                                key={index + "" + element.name}
                              >
                                <Card
                                  title={element.name ? element.name : false}
                                  location={
                                    element.location ? element.location : false
                                  }
                                  postalcode={
                                    element.postal_code
                                      ? element.postal_code
                                      : false
                                  }
                                  page={element.page ? element.page : ""}
                                  type={pillsimilar}
                                  salary={
                                    element.salary_value
                                      ? element.salary_value
                                      : false
                                  }
                                  desc={element.detail ? element.detail : false}
                                  detail_url={element.page + "/" + element.slug}
                                  views={element.views}
                                />
                                {/* { index === 1  &&  <div>
                                    <NativeAd/>
                                    <Divider/>
                                </div> }
                                { lastIndex.length-1 < 1  &&  <div>
                                    <NativeAd/>
                                    <Divider/>
                                </div> } */}
                              </div>
                            </React.Fragment>
                          );
                        }
                      })
                    : false}
                  {data && data.length === 0 && (
                    <div name="notfound">
                      <NoRecordFound />
                    </div>
                  )}
                </div>
                {data &&
                  data.length > 0 &&
                  paginationData &&
                  paginationData.links.length > 3 && (
                    <div className="col-lg-12 col-md-12 col-sm-12 col-9">
                      <Pagination
                        totalPages={paginationData.last_page}
                        currentPage={paginationData.current_page}
                        onPageChange={handlePagination}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}

      <Divider />
      {dataPage === "business" && (
        <>
          <PopularCategories />
          <Divider />
        </>
      )}
      {advertise}
    </>
  );
}
export default SearchListing;
