import React from "react";
import { useHistory } from "react-router-dom";
import { Search } from "react-bootstrap-icons";
import SelectCustom from "../common/SelectCustom";
import BannerImage from "../../gallery/banner.jpg";

function EventsBanner({ locations }) {
  let locationSelectedOption = {
    label: "Chose your location",
    value: 1,
  };

  const history = useHistory();

  const handleRedirect = (e) => {
    e.preventDefault();
    let location_id = 1;

    var locSelect = document.getElementById("location_select");
    if (locSelect.title !== "") {
      location_id = locSelect.title;
    }
    history.push(`/search?category=4&location=${location_id}&page=1&limit=10`);
  };
  return (
    <>
      <div className="container-fluid">
        <section className="hero hero--video">
          <div className="container">
            <div className="col-12">
              <div className="search">
                <h6 className="banner-text-1">जे खोजिन्छ, मज्जाले भेटिन्छ</h6>
                <h6 className="banner-text-2">
                  Find Nepalese events in your city
                </h6>
                <div className="row">
                  <div className="col-lg-4 col-md-3 hidden-mobile"></div>
                  {locations && (
                    <div className="col-lg-3 col-md-4 col-12 location-select">
                      <SelectCustom
                        id={"location_select"}
                        //placeHolder='Town or City'
                        options={locations}
                        selectedOption={locationSelectedOption}
                      />
                    </div>
                  )}
                  <div className="col-lg-1 col-md-2 col-12">
                    <button
                      onClick={handleRedirect}
                      className="btn-search-danger"
                    >
                      <Search size={15} style={{ marginRight: "5px" }} />
                      Search
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-3 hidden-mobile"></div>
                </div>
              </div>
            </div>
          </div>
          <img
            className="d-lg-block"
            src={BannerImage}
            alt="Banner"
            style={{ opacity: "0.3" }}
          />
        </section>
      </div>
    </>
  );
}

export default EventsBanner;
