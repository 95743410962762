import React from 'react'
import { Helmet } from 'react-helmet-async';

export default function PropertySearch(props) {
  
  function handlePropertyType(event) {

    var selectedValue = event.target.value;
    props.handle(selectedValue, 'property')
  }
  function handleGender(event) {

    var selectedValue = event.target.value;
    props.handle(selectedValue, 'gender')
  }
  function handleCheck(event,paramType) {
    if(event.target.checked){
      event.target.checked = true
      props.handle(1, paramType)
    }else{
      event.target.checked = false
      props.handle(0, paramType)
    }
  }

  return (
    <div className='property-search hidden-xs hidden-sm'>
      <Helmet>
            <title>{`Sahi Search - ${props.pageTitle}`}</title>
            <meta name="description" content="" />
        </Helmet>
      <div className='row'>
        <div className='col-lg-12 col-md-12 col-sm-12 mb-3'>
            <label className='form-label'>Type of Property</label>
            <select
            defaultValue={props.type} 
            onChange={(event)=>handlePropertyType(event)} 
            className="form-select" aria-label="Default select example">
                <option value='0'>Choose your property type</option>
                <option value="1">Single Room</option>
                <option value="2">Double Room</option>
                <option value="3">Box Room</option>
                <option value="4">Studio Flat</option>
                <option value="5">Flat</option>
                <option value="6">House</option>
            </select>
        </div>
        <div className='col-lg-12 col-md-12 col-sm-12 mb-3'>
            <label className='form-label'>Gender preference</label>
            <select defaultValue={props.gender} onChange={(event)=>handleGender(event)} name='genderSelection' className="form-select">
                <option value={3}>Any</option>
                <option value={1}>Male</option>
                <option value={2}>Female</option>
            </select>
        </div>
        <div className='col-lg-12 col-md-12 col-sm-12'>
        <hr className='section-divider'/>
        </div>
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <div className='row radio-filter'>
            <div className='col-lg-9 col-md-9 col-9'>
              <label className="form-check-label">Council tax included</label>
            </div>
            <div className='col-lg-3 col-md-3 col-3'>
              <div className="form-check form-switch">
                <input checked={parseInt(props.ctax) === parseInt(1)?true:false} onChange={(event)=>handleCheck(event,'ctax')} className="form-check-input" type="checkbox"/>
              </div>
            </div>
          </div>
          <div className='row radio-filter'>
            <div className='col-lg-9 col-md-9 col-9'>
              <label className="form-check-label">Sharing allowed</label>
            </div>
            <div className='col-lg-3 col-md-3 col-3'>
              <div className="form-check form-switch">
                <input checked={parseInt(props.sharing) === parseInt(1)?true:false} onChange={(event)=>handleCheck(event,'sharing')} name='sharingAllowed' className="form-check-input" type="checkbox"/>
              </div>
          </div>
          </div>
          <div className='row radio-filter'>
            <div className='col-lg-9 col-md-9 col-9'>
              <label className="form-check-label">Deposit required</label>
            </div>
            <div className='col-lg-3 col-md-3 col-3'>
              <div className="form-check form-switch">
                <input checked={parseInt(props.deposit) === parseInt(1)?true:false} onChange={(event)=>handleCheck(event,'deposit')}name='depositRequire' className="form-check-input" type="checkbox"/>
              </div>
            </div>
          </div>
          <div className='row radio-filter'>
            <div className='col-lg-9 col-md-9 col-9'>
              <label className="form-check-label">Bills included</label>
            </div>
            <div className='col-lg-3 col-md-3 col-3'>
              <div className="form-check form-switch">
                <input checked={parseInt(props.bills) === parseInt(1)?true:false} onChange={(event)=>handleCheck(event,'bills')}name='billsInculded' className="form-check-input" type="checkbox"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
